import { useEffect } from "react";
import { Box, TextInput, Button } from "@mantine/core";
import { IconSearch, IconAdjustmentsHorizontal } from "@tabler/icons-react";
import { Helmet } from 'react-helmet-async';
import styled from "styled-components";
import CompetitionCard from "../../features/Competition/components/CompetitionCard";
import FilterModal from "../../features/Competition/components/FilterModal";
import { useState } from "react";
import { useGetAllCompetition } from "../../features/Competition/hooks/useGetAllCompetition";
import { useInView } from "react-intersection-observer";
import CompetitionCardSkeleton from "../../features/Competition/components/skeletons/CompetitionCardSkeleton";
import EmptyState from "../../features/Competition/components/EmptyState";
import { useTranslation } from "react-i18next";
import { useDebouncedValue } from '@mantine/hooks';

const CompetitionList = () => {
  const { t } = useTranslation();
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [filters, setFilters] = useState({});

  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearch] = useDebouncedValue(searchTerm, 500);
  const { ref, inView } = useInView();

  const {
    data,
    isLoading,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetAllCompetition(filters, debouncedSearch);

  const allCompetitions = data?.pages.flatMap((page) => page.results) || [];
  const hasCompetitions = allCompetitions.length > 0;

  const handleResetFilters = () => {
    setFilters({});
    setSearchTerm("");
  };

  const handleSearch = (filterParams) => {
    setFilters(filterParams);
    setIsFilterModalOpen(false);
  };

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, isFetchingNextPage]);

  const checkIfExpired = (endDate, endTime) => {
    if (!endDate || !endTime || endDate === "N/A" || endTime === "N/A")
      return false;

    try {
      // Parse the date from "2025-01-30T20:00:00Z" format
      const end = new Date(endDate);

      // Parse time from "11:40" format
      const [hours, minutes] = endTime.split(":");

      // Set the hours and minutes
      end.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0);

      // Compare with current date and time
      return new Date() > end;
    } catch (error) {
      console.error("Error checking expiration:", error);
      return false;
    }
  };

  return (
    <>
      <Helmet>
        <title>{t('competitionList.title')} | TalentScore</title>
        <meta 
          name="description" 
          content={t('competitionList.metaDescription')} 
        />
        <meta 
          name="keywords" 
          content={t('competitionList.metaKeywords')} 
        />
        {/* Open Graph tags for social sharing */}
        <meta 
          property="og:title" 
          content={`${t('competitionList.title')} | TalentScore`} 
        />
        <meta 
          property="og:description" 
          content={t('competitionList.metaDescription')} 
        />
        <meta 
          property="og:type" 
          content="website" 
        />
        <meta 
          property="og:url" 
          content="https://talentscore.az/competitions" 
        />
        {/* Twitter Card tags */}
        <meta 
          name="twitter:card" 
          content="summary_large_image" 
        />
        <meta 
          name="twitter:title" 
          content={`${t('competitionList.title')} | TalentScore`} 
        />
        <meta 
          name="twitter:description" 
          content={t('competitionList.metaDescription')} 
        />
      </Helmet>

      <Wrapper>
        <Box className="header">
          <h1>{t("competitionList.title")}</h1>

          <Box className="search-section">
            <TextInput
              placeholder={t("competitionList.searchPlaceholder")}
              icon={<IconSearch size={16} />}
              className="search-input"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Button
              leftIcon={<IconAdjustmentsHorizontal size={16} />}
              variant="outline"
              onClick={() => setIsFilterModalOpen(true)}
              className="filter-button"
            >
              {t("competitionList.filterButton")}
            </Button>
          </Box>
        </Box>

        <Box className="section-title">
          <h2>{t("competitionList.olympiadHackathon")}</h2>
        </Box>

        {isLoading ? (
          <Grid>
            {Array.from({ length: 8 }).map((_, index) => (
              <CompetitionCardSkeleton key={index} />
            ))}
          </Grid>
        ) : !hasCompetitions ? (
          <EmptyState onReset={handleResetFilters} />
        ) : (
          <>
            <Grid>
              {allCompetitions.map((competition) => (
                <CompetitionCard
                  id={competition.id}
                  key={competition.id}
                  slug={competition.slug}
                  title={competition.name}
                  type={competition.is_hackathon ? "hackathon" : "olympic"}
                  level={competition.olympiad_level || "N/A"}
                  date={competition.olympiad_dates?.[0] || "N/A"}
                  skills={competition.skills_list || []}
                  isFree={competition.price === "0.00"}
                  company={competition.company_profile}
                  isDone={checkIfExpired(
                    competition.olympiad_dates?.[0]?.end_date,
                    competition.olympiad_dates?.[0]?.end_time
                  )}
                />
              ))}
            </Grid>

            {/* Infinite scroll trigger */}
            {!error && hasNextPage && (
              <div ref={ref} style={{margin: "20px 0" }}>
                {isFetchingNextPage && (
                  <Grid  >
                    {Array.from({ length: 4 }).map((_, index) => (
                      <CompetitionCardSkeleton
                        key={`infinite-scroll-skeleton-${index}`}
                      />
                    ))}
                  </Grid>
                )}
              </div>
            )}
          </>
        )}

        <FilterModal
          opened={isFilterModalOpen}
          onClose={() => setIsFilterModalOpen(false)}
          onSearch={handleSearch}
        />
      </Wrapper>
    </>
  );
};

export default CompetitionList;

const Wrapper = styled.div`
  padding: 40px;
  max-width: 95%;
  margin: 0 auto;

  @media (max-width: 1200px) {
    padding: 32px;
  }

  @media (max-width: 768px) {
    padding: 24px;
    max-width: 98%;
  }

  @media (max-width: 480px) {
    padding: 16px;
    max-width: 100%;
  }

  .header {
    text-align: center;
    margin-bottom: 48px;

    @media (max-width: 768px) {
      margin-bottom: 32px;
    }

    h1 {
      font-size: 32px;
      font-weight: 600;
      color: #202939;
      margin-bottom: 24px;
      line-height: 1.2;

      @media (max-width: 768px) {
        font-size: 28px;
        margin-bottom: 20px;
      }

      @media (max-width: 480px) {
        font-size: 20px;
        margin-bottom: 16px;
      }
    }
  }

  .search-section {
    display: flex;
    gap: 12px;
    max-width: 600px;
    margin: 0 auto;

    .search-input {
      flex: 1;

      input {
        height: 40px;
        border-radius: 8px;
      }
    }

    .filter-button {
      height: 40px;
      border-radius: 8px;
      background-color: #0291991a;
      color: #029199;
      border: 1px solid #029199;

      &:hover {
        background-color: #0291991a;
        opacity: 0.9;
      }
    }
  }

  .section-title {
    margin: 32px 0 24px;

    @media (max-width: 768px) {
      margin: 24px 0 20px;
    }

    h2 {
      font-size: 24px;
      font-weight: 600;
      color: #202939;

      @media (max-width: 768px) {
        font-size: 22px;
      }

      @media (max-width: 480px) {
        font-size: 18px;
      }
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 24px;
  width: 100%;

  @media (max-width: 1600px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 1380px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  @media (max-width: 640px) {
    grid-template-columns: 1fr;
    gap: 16px;
  }
`;
