import React, { useEffect, useState, useRef, useContext } from "react";
import logo from "../../../Assets/SVGs/logo/bgLogo.svg";
import styled from "styled-components";
import { CustomDropdown } from "./CustomDropdown";
import { useTranslation } from "react-i18next";
import {
  IconSearch,
  IconChevronDown,
  IconUser,
  IconSchool,
  IconSettings,
  IconLogout,
  IconMenu2,
} from "@tabler/icons-react";
import { Button } from "@mantine/core";
import { Link, useLocation } from "react-router-dom";
import subsIcon from "../../../Assets/SVGs/subscription-icon.svg";
import defaultAvatar from "../../../Assets/SVGs/header/user.png";
import { useMediaQuery } from "@mantine/hooks";
import { cookieUtils } from "../../../utils/cookieUtils";
import MenuBar from "./MenuBar";
import { AppContext } from "../../../Helpers/Context";

const UserHeader = () => {
  const { t, i18n } = useTranslation();
  const [selectedLang, setSelectedLang] = useState(
    localStorage.getItem("tsklng") || "az"
  );
  const { user } = useContext(AppContext);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const profileDropdownRef = useRef(null);

  const changeLang = (lng) => {
    i18n.changeLanguage(lng);
    setSelectedLang(lng);
    localStorage.setItem("tsklng", lng);
  };

  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const xs = useMediaQuery("(max-width: 800px)");
  const { pathname } = useLocation();

  function logout() {
    localStorage.setItem("taskooluser", null);
    cookieUtils.clearAuthCookies();
    window.location = "/";
  }

  const onClose = () => {
    setIsMobileMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        profileDropdownRef.current &&
        !profileDropdownRef.current.contains(event.target)
      ) {
        setIsProfileOpen(false);
      }
    };

    if (isProfileOpen && window.innerWidth <= 768) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isProfileOpen]);

  const xl = useMediaQuery("(max-width: 1440px)");
  const md = useMediaQuery("(max-width: 1120px)");
  const size360 = useMediaQuery("(max-width: 400px)");

  useEffect(() => {
    const handleClickOutside = (event) => {
      const menuBarElement = document.querySelector('[data-menubar]');
      const menuButton = document.querySelector('[data-menu-button]');
      
      if (
        isMobileMenuOpen &&
        menuBarElement &&
        !menuBarElement.contains(event.target) &&
        !menuButton.contains(event.target)
      ) {
        setIsMobileMenuOpen(false);
      }
    };

    if (isMobileMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobileMenuOpen]);

  return (
    <>
      <UserHeaderStyled
        path={pathname}
        style={
          scrolled
            ? {
                boxShadow: "0 4px 15px 0 rgba(0, 0, 0, 0.1)",
                padding: xs ? "4px 0" : "8px 0",
                transition: "all 0.7s ease",
              }
            : { padding: "12px 0" }
        }
      >
        <div className="container">
          <MobileMenuButton
            data-menu-button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            <IconMenu2 color="#364152" size={24} />
          </MobileMenuButton>

          {md && !xs && (
            <LogoContainer>
              <Link to="/home">
                <img src={logo} alt="logo" />
              </Link>
            </LogoContainer>
          )}

          {!md && (
            <LeftContainer>
              <LogoContainer>
                <Link to="/home">
                  <img src={logo} alt="logo" />
                </Link>
              </LogoContainer>
              <NavbarContainer>
                <CustomDropdown
                  buttonLabel={t("about")}
                  menuItems={[
                    { label: t("about"), link: "/about" },
                    { label: "STEAMLAND", link: "/steamland" },
                  ]}
                  isUser={true}
                />
                <CustomDropdown
                  buttonLabel={t("steam")}
                  menuItems={[
                    { label: t("steam-bank"), link: "/steam" },
                    { label: t("olympic"), link: "/competitions" },
                    { label: t("course"), link: "/bootcamps" },
                  ]}
                  isUser={true}

                />
                <CustomDropdown
                  buttonLabel={t("education")}
                  menuItems={[
                    { label: t("study-abroad"), link: "/universities" },
                    { label: t("tip"), link: "/tip", showOnlyIn: "az" },
                  ]}
                  isUser={true}
                />
                <CustomDropdown
                  isNoneDropdown={true}
                  buttonLabel={t("community")}
                  link="/communities"
                  isUser={true}
                />
              </NavbarContainer>
            </LeftContainer>
          )}
          <RightContainer>
            {!(xl && isProfileOpen) ? (
              <Button
                component={Link}
                to="/subscribtion"
                className="subscription-button"
                leftIcon={<img src={subsIcon} alt="Subs-Icon" />}
              >
                {t("subscription")}
              </Button>
            ) : null}

            {!(size360 && isProfileOpen) && (
              <div className="language-container">
                <CustomDropdown
                  buttonLabel={selectedLang.toUpperCase()}
                  menuItems={[
                    { label: "AZ", value: "az" },
                    { label: "EN", value: "en" },
                    { label: "RU", value: "ru" },
                    { label: "TR", value: "tr" },
                  ]}
                  isLanguage={true}
                  onItemClick={changeLang}
                  isUser={true}
                />
              </div>
            )}

            <ProfileDropdown ref={profileDropdownRef} isOpen={isProfileOpen}>
              <ProfileButton
                isOpen={isProfileOpen}
                onClick={() => setIsProfileOpen(!isProfileOpen)}
              >
                <Avatar
                  src={
                    user?.profile_img &&
                    user?.profile_img.includes("user-profile")
                      ? user?.profile_img
                      : defaultAvatar
                  }
                  alt="Profile"
                />
                <span>{t("me")}</span>
                <IconChevronDown color="#364152" size={16} />
              </ProfileButton>
              <DropdownContent className="dropdown-content">
                <DropdownItem href="/profile">
                  <IconUser size={20} />
                  My Profile
                </DropdownItem>
                <DropdownItem href="/universities/portal">
                  <IconSchool size={20} />
                  University Portal
                </DropdownItem>
                <DropdownItem onClick={logout}>
                  <IconLogout size={20} />
                  Log out
                </DropdownItem>
              </DropdownContent>
            </ProfileDropdown>
          </RightContainer>
        </div>
      </UserHeaderStyled>
      <MenuBarWrapper data-menubar isOpen={isMobileMenuOpen}>
        <MenuBar onClose={onClose} />
      </MenuBarWrapper>
    </>
  );
};

export default UserHeader;

const UserHeaderStyled = styled.div`
  margin-bottom: ${(props) =>
    props.path === "/" ||
    props.path?.includes("tip") ||
    props.path?.includes("overview") ||
    props.path?.includes("week-pathway") ||
    props.path?.includes("section-pathway") ||
    props.path?.includes("task-reviews") ||
    props.path?.includes("universities") ||
    props.path?.includes("payment") ||
    props.path?.includes("agents") ||
    props.path?.includes("steamland") ||
    props.path?.includes("competition")
      ? "0px"
      : "20px"};
  background: #fff;
  padding: 15px 0;
  width: 100%;
  box-shadow: 0 4px 15px 0 (0, 0, 0, 0, 1);
  position: sticky; // Əlavə edildi
  top: 0; // Əlavə edildi
  left: 0;
  z-index: 10;

  .container {
    display: grid;
    grid-template-columns: auto 0.5fr;
    align-items: center;
    max-width: ${(props) => (props.path?.includes("steam") ? "95%" : "90%")};
    margin: 0 auto;
    @media (max-width: 1440px) {
      // max-width: 1140px;
      margin: 0 auto;
    }
    @media (max-width: 1280px) {
      // max-width: 1080px;
      max-width: 95%;
      margin: 0 auto;
    }
    @media (max-width: 1120px) {
      // max-width: 970px;
      grid-template-columns: 0.1fr 0.5fr auto;

      margin: 0 auto;
    }
    @media (max-width: 990px) {
      // max-width: 720px;
      margin: 0 auto;
    }
    @media (max-width: 800px) {
      grid-template-columns: 0.1fr auto;
    }
    @media (max-width: 720px) {
      // max-width: 546px;
      justify-content: space-beetwen;
      .subscription-button {
        display: none;
      }
    }
    @media (max-width: 576px) {
      max-width: 90%;
      margin: 0 auto;
    }
  }
`;

const MobileMenuButton = styled.button`
  display: none;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;

  @media (max-width: 1120px) {
    display: block;
  }
`;
const MenuBarWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 80%;
  height: 100vh;
  background: #029199;
  transform: translateX(${(props) => (props.isOpen ? "0" : "-100%")});
  transition: transform 0.3s ease-in-out;
  z-index: 11;
  @media screen and (max-width: 1120px) {
    width: 40%;
  }
  @media screen and (max-width: 720px) {
    width: 50%;
  }
  @media screen and (max-width: 576px) {
    width: 80%;
  }
`;

const LogoContainer = styled.div`
  img {
    height: 53px;
    width: 136px;
  }
`;

const NavbarContainer = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
`;

const LeftContainer = styled.div`
  display: flex;
  gap: 40px;
  align-items: center;
`;

const RightContainer = styled.div`
  display: flex;
  gap: 0px;
  align-items: center;
  justify-content: flex-end;

  .search-icon {
    border-right: 1px solid #e5e7eb;
    padding-right: 16px;
    cursor: pointer;
  }
  .language-container {
    position: relative;
    &::after {
      content: "";
      width: 1px;
      height: 50%;
      background: #364152;
      position: absolute;
      right: -3px;
      top: 50%;
      transform: translateY(-50%);
    }
    &::before {
      content: "";
      width: 1px;
      height: 50%;
      background: #364152;
      position: absolute;
      left: -3px;
      top: 50%;
      transform: translateY(-50%);
    }

    @media (max-width: 768px) {
      &::before {
        display: none;
      }
    }
  }

  /* Update subscription button styles */
  .subscription-button {
    background: #ccf075;
    color: #364152;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 32px;
    border-radius: 14px;
    display: flex;
    align-items: center;
    gap: 8px;
    height: 45px;
    margin-right: 26px;

    &:hover {
      background: #bde164;
    }

    img {
      width: 20px;
      height: 20px;
    }
  }
`;

const ProfileButton = styled.button`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 8px;
  background: ${(props) => (props.isOpen ? "#f5f5f5" : "none")};
  border: none;
  cursor: pointer;
  padding: 8px;
  border-radius: ${(props) => (props.isOpen ? "14px" : "8px")};
  width: ${(props) => (props.isOpen ? "200px" : "120px")};
  min-width: fit-content;
  transition: width 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  z-index: 1001;
  box-shadow: ${(props) =>
    props.isOpen ? "0px 4px 15px 0px rgba(0, 0, 0, 0.1)" : "none"};

  span {
    white-space: nowrap;
    text-align: left;
    font-size: 16px;
    color: #364152;
  }

  svg {
    justify-self: end;
    transition: transform 0.3s ease;
    transform: ${(props) => (props.isOpen ? "rotate(180deg)" : "rotate(0deg)")};
  }

  @media (max-width: 1390px) {
    width: ${(props) => (props.isOpen ? "200px" : "100px")};
    font-size: 14px;
  }
`;

const ProfileDropdown = styled.div`
  position: relative;
  margin-left: 15px;

  @media (min-width: 769px) {
    &:hover {
      ${ProfileButton} {
        width: 200px;
        background-color: #f5f5f5;
        border-radius: 14px;
        background: #fff;
        box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
        transition: width 0.3s ease, background-color 0.3s ease,
          box-shadow 0.3s ease;

        svg {
          transform: rotate(180deg);
          @media (max-width: 768px) {
            transform: rotate(0deg);
          }
          margin-left: auto;
        }
      }

      .dropdown-content {
        opacity: 1;
        transform: translateY(0);
        visibility: visible;
      }
    }
  }

  @media (max-width: 768px) {
    .dropdown-content {
      opacity: ${(props) => (props.isOpen ? "1" : "0")};
      transform: ${(props) =>
        props.isOpen ? "translateY(0)" : "translateY(-10px)"};
      visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
    }
  }
`;

const Avatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
`;

const DropdownContent = styled.div`
  position: absolute;
  top: 80%;
  right: 0;
  background-color: white;
  min-width: 200px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 8px 0;
  z-index: 1000;

  /* Add smooth transition for dropdown */
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: all 0.3s ease;
`;

const DropdownItem = styled.a`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px 16px;
  color: #364152;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
  &:first-child {
    margin-top: 10px;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;
