import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const MyResultsSection = ({ myScore,slug, competitionId, userId }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleScoreClick = () => {
    navigate(`/competition/${slug}-${competitionId}/student/${userId}`);
  };


  return (
    <ResultsWrapper>
      <ScoreSection onClick={handleScoreClick}>
        <ScoreLabel>{t("results.score")}</ScoreLabel>
        <ScoreBox>{myScore?.result || 0}</ScoreBox>
      </ScoreSection>
    </ResultsWrapper>

  );
};

const ResultsWrapper = styled.div`
  border-radius: 8px;
`;

const ScoreLabel = styled.div`
  color: #029199;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  position: relative;
  display: inline-block;
  transition: all 0.3s ease;
  padding: 4px 0px;
  border-radius: 4px;
  
  &::after {
    content: "→";
    margin-left: 6px;
    display: inline-block;
    transition: all 0.3s ease;
    opacity: 0.7;
  }

  &:hover {
    color: white;
    background: #029199;
    
    &::after {
      transform: translateX(4px);
    }
  }
`;

const ScoreBox = styled.div`
  // background: #F8FAFC;
  border-radius: 8px;
  padding: 12px 4px;
  color: #334155;
  font-size: 16px;
  transition: all 0.3s ease;
`;

const ScoreSection = styled.div`
  margin-bottom: 24px;
  padding: 16px 4px;
  border-radius: 12px;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  
  &:hover {
    border-color: #029199;
    background: rgba(2, 145, 153, 0.05);
    transform: scale(1.02);
    box-shadow: 0 8px 16px rgba(2, 145, 153, 0.1);
    
    ${ScoreLabel} {
      transform: translateX(4px);
      
      &::after {
        transform: translateX(4px);
      }
    }
    
    ${ScoreBox} {
      background: white;
      transform: translateY(-2px);
    }
  }
  
  &:active {
    transform: scale(0.98);
    box-shadow: 0 4px 8px rgba(2, 145, 153, 0.1);
  }
`;

export default MyResultsSection;