import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { Box, Radio, Group, Text, Modal, Button } from "@mantine/core";
import { RichTextEditor } from "@mantine/rte";
import { IconDownload, IconFile, IconPaperclip } from "@tabler/icons-react";
import { useStartTask } from "../hooks/tasks/useStartTask";
import { useSolveTask } from "../hooks/tasks/useSoilveTask";
import { useGetSolveTask } from "../hooks/tasks/useGetSolveTask";
import { showNotification } from "@mantine/notifications";
import { useMediaQuery } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import TaskSectionSkeleton from "./skeletons/TaskSectionSkeleton";

const TaskSection = ({ isHackathon, tasks, competitionId, slug }) => {
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [taskAnswers, setTaskAnswers] = useState(() => {
    // Load saved answers from localStorage if they exist
    const savedAnswers = localStorage.getItem(`task_answers_${competitionId}`);
    return savedAnswers ? JSON.parse(savedAnswers) : {};
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const currentTask = tasks?.[currentTaskIndex];
  const isQuiz = currentTask?.is_quiz;

  const { mutate: startTask } = useStartTask(
    slug,
    competitionId,
    currentTask?.id
  );
  const { mutate: solveTask } = useSolveTask(
    slug,
    competitionId,
    currentTask?.id
  );
  const { data: solveTaskData, isLoading } = useGetSolveTask(
    slug,
    competitionId,
    currentTask?.id
  );

  // Check if task is completed
  const isTaskCompleted = !!solveTaskData?.question_answer?.length;

  // Start task when switching to a new task
  useEffect(() => {
    if (currentTask?.id && !taskAnswers[currentTask.id]?.started) {
      startTask(null, {
        onSuccess: () => {
          setTaskAnswers((prev) => ({
            ...prev,
            [currentTask.id]: { ...prev[currentTask.id], started: true },
          }));
        },
      });
    }
  }, [currentTask?.id]);

  // Save answers to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem(
      `task_answers_${competitionId}`,
      JSON.stringify(taskAnswers)
    );
  }, [taskAnswers, competitionId]);

  const handleQuizAnswer = (questionId, answerId) => {
    setTaskAnswers((prev) => ({
      ...prev,
      [currentTask.id]: {
        ...prev[currentTask.id],
        answers: {
          ...(prev[currentTask.id]?.answers || {}),
          [questionId]: { type: "quiz", answerId },
        },
      },
    }));
  };

  const handleOpenAnswer = (questionId, answerText, files = []) => {
    setTaskAnswers((prev) => ({
      ...prev,
      [currentTask.id]: {
        ...prev[currentTask.id],
        answers: {
          ...(prev[currentTask.id]?.answers || {}),
          [questionId]: {
            type: "open",
            answerText,
            files: [
              ...(prev[currentTask.id]?.answers?.[questionId]?.files || []),
              ...files,
            ],
          },
        },
      },
    }));
  };

  const handleFileUpload = async (questionId, event) => {
    const maxFiles =
      currentTask?.task_question?.find((q) => q.id === questionId)?.max_files ||
      0;
    const currentFiles =
      taskAnswers[currentTask.id]?.answers?.[questionId]?.files || [];
    const newFiles = Array.from(event.target.files);

    if (currentFiles.length + newFiles.length > maxFiles) {
      showNotification({
        title: "File limit exceeded",
        message: `You can only upload a maximum of ${maxFiles} files for this question.`,
        color: "red",
        autoClose: 3000,
      });
      return;
    }

    const filePromises = newFiles.map((file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve({
            name: file.name,
            base64: reader.result,
            type: file.type,
          });
        };
        reader.readAsDataURL(file);
      });
    });

    const base64Files = await Promise.all(filePromises);
    const currentAnswerText =
      taskAnswers[currentTask.id]?.answers?.[questionId]?.answerText || "";
    handleOpenAnswer(questionId, currentAnswerText, base64Files);
  };

  const handleTaskComplete = () => {
    if (isTaskCompleted || isSubmitting) return;

    setIsSubmitting(true);
    const currentAnswers = taskAnswers[currentTask.id]?.answers || {};
    const allQuestionIds = currentTask?.task_question?.map(q => q.id) || [];

    // Check quiz answers first


    const formattedAnswers = allQuestionIds.map(questionId => {
      const answer = currentAnswers[questionId];
      
      if (!answer) {
        return isQuiz ? {
          question: questionId,
          answer: null,
        } : {
          question: questionId,
          answer_text: "",
          olympiad_student_task_file: [],
        };
      }

      return {
        question: questionId,
        ...(answer.type === "quiz"
          ? { answer: answer.answerId }
          : {
              answer_text: answer.answerText,
              olympiad_student_task_file: answer.files?.map((file) => ({
                file: file.base64,
              })),
            }),
      };
    });

    solveTask(
      { olympiad_student_task_question_answer: formattedAnswers },
      {
        onSuccess: () => {
          // Remove completed task from localStorage
          const updatedAnswers = { ...taskAnswers };
          delete updatedAnswers[currentTask.id];
          setTaskAnswers(updatedAnswers);

          // Clear all task data from localStorage for this competition
          localStorage.removeItem(`task_answers_${competitionId}`);
          localStorage.removeItem(`competition_${competitionId}_end_warning`);

          // Move to next task if available
          if (currentTaskIndex < tasks.length - 1) {
            setCurrentTaskIndex((prev) => prev + 1);
          }
        },
        onSettled: () => {
          setIsSubmitting(false);
          setShowConfirmModal(false);
        },
      }
    );
  };

  const handleCompleteClick = () => {
    setShowConfirmModal(true);
  };

  const handleNextTask = () => {
    if (currentTaskIndex < tasks.length - 1) {
      setCurrentTaskIndex((prev) => prev + 1);
    }
  };

  const handlePrevTask = () => {
    if (currentTaskIndex > 0) {
      setCurrentTaskIndex((prev) => prev - 1);
    }
  };

  const handleDeleteFile = (questionId, fileIndex) => {
    setTaskAnswers((prev) => {
      const currentAnswers = { ...prev };
      const currentFiles = [
        ...(currentAnswers[currentTask.id]?.answers?.[questionId]?.files || []),
      ];

      // Remove the file at the specified index
      currentFiles.splice(fileIndex, 1);

      // Update the state with the new files array
      currentAnswers[currentTask.id] = {
        ...currentAnswers[currentTask.id],
        answers: {
          ...currentAnswers[currentTask.id]?.answers,
          [questionId]: {
            ...currentAnswers[currentTask.id]?.answers?.[questionId],
            files: currentFiles,
          },
        },
      };

      return currentAnswers;
    });
  };

  const xs = useMediaQuery("(max-width: 408px)");

  const { t } = useTranslation();

  if (isLoading) return <TaskSectionSkeleton />;
  return (
    <TaskWrapper>
      <Text mt={24} mb={30} size={22} fw={600} color="#364152">
        {t("task")} {currentTaskIndex + 1}
      </Text>

      {/* Task Description and Files */}
      <TaskHeader>
        <Group>
          <div dangerouslySetInnerHTML={{ __html: currentTask?.description }} />
        </Group>
      </TaskHeader>

      {/* Task Files Section */}
      {currentTask?.task_files?.length > 0 && (
        <FileSection>
          <FileTitle>Files</FileTitle>
          <FileList>
            {currentTask.task_files.map((file) => (
              <FileItem key={file.id}>
                <FileInfo>
                  <IconFile size={20} />
                  <span>PDF file</span>
                </FileInfo>
                <DownloadButton
                  as="a"
                  href={file.file_url}
                  download
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Download file <IconDownload size={16} />
                </DownloadButton>
              </FileItem>
            ))}
          </FileList>
        </FileSection>
      )}

      {/* Questions Section */}
      {isQuiz ? (
        <QuizSection>
          {currentTask?.task_question?.map((question, index) => {
            const solvedAnswer = solveTaskData?.question_answer?.find(
              (qa) => qa.question === question.id
            );

            return (
              <QuizQuestionWrapper key={question.id}>
                <Question>
                  <span>{index + 1}. </span>
                  <div
                    dangerouslySetInnerHTML={{ __html: question.description }}
                  />
                </Question>

                {question.file_url && (
                  <QuestionFileWrapper>
                    <QuestionImage
                      src={question.file_url}
                      alt={`Question ${index + 1} image`}
                      onClick={() => window.open(question.file_url, "_blank")}
                    />
                  </QuestionFileWrapper>
                )}

                <OptionsGroup>
                  {question.question_answers.map((option) => {
                    const isSelected = isTaskCompleted
                      ? solvedAnswer?.answer === option.id
                      : taskAnswers[currentTask.id]?.answers?.[question.id]
                          ?.answerId === option.id;

                    return (
                      <OptionWrapper
                        key={option.id}
                        onClick={() =>
                          !isTaskCompleted &&
                          handleQuizAnswer(question.id, option.id)
                        }
                        $isSelected={isSelected}
                        $isCompleted={isTaskCompleted}
                      >
                        <RadioInput
                          type="radio"
                          name={`question-${question.id}`}
                          checked={isSelected}
                          disabled={isTaskCompleted}
                          onChange={() =>
                            !isTaskCompleted &&
                            handleQuizAnswer(question.id, option.id)
                          }
                        />
                        <LinkPreview>
                          <Text size="sm">{option.answer}</Text>
                        </LinkPreview>
                      </OptionWrapper>
                    );
                  })}
                </OptionsGroup>
              </QuizQuestionWrapper>
            );
          })}
        </QuizSection>
      ) : (
        <QuestionSection>
          {currentTask?.task_question?.map((question, index) => {
            const solvedAnswer = solveTaskData?.question_answer?.find(
              (qa) => qa.question === question.id
            );

            return (
              <QuestionWrapper key={question.id}>
                <Question>
                  <span>{index + 1}. </span>
                  <div
                    dangerouslySetInnerHTML={{ __html: question.description }}
                  />
                </Question>

                {question.file_url && (
                  <QuestionFileWrapper>
                    <QuestionImage
                      src={question.file_url}
                      alt={`Question ${index + 1} image`}
                      onClick={() => window.open(question.file_url, "_blank")}
                    />
                  </QuestionFileWrapper>
                )}

                <EditorWrapper>
                  <RichTextEditor
                    value={
                      isTaskCompleted
                        ? solvedAnswer?.answer_text
                        : taskAnswers[currentTask.id]?.answers?.[question.id]
                            ?.answerText || ""
                    }
                    onChange={(value) =>
                      !isTaskCompleted && handleOpenAnswer(question.id, value)
                    }
                    readOnly={isTaskCompleted}
                    controls={[
                      ["bold", "italic", "underline"],
                      ["h1", "h2", "h3"],
                      ["unorderedList", "orderedList"],
                      ["link", "image"],
                      ["alignLeft", "alignCenter", "alignRight"],
                    ]}
                    styles={{
                      root: {
                        backgroundColor: isTaskCompleted
                          ? "#f5f5f5"
                          : "#F9FAFB",
                        opacity: isTaskCompleted ? 0.8 : 1,
                      },
                      toolbar: {
                        border: "none",
                        backgroundColor: "#F9FAFB",
                        display: isTaskCompleted ? "none" : "flex",
                      },
                      content: {
                        backgroundColor: isTaskCompleted
                          ? "#f5f5f5"
                          : "#F9FAFB",
                        minHeight: "200px",
                        "& .ql-editor": {
                          padding: "16px",
                          fontSize: "14px",
                          color: "#575757",
                          fontFamily: "inherit",
                        },
                      },
                    }}
                    placeholder="Write your answer here..."
                  />

                  {/* Only show file section if question type is hackathon */}
                  {isHackathon && (
                    <FileUploadWrapper>
                      {!isTaskCompleted ? (
                        <FileInputContainer>
                          <FileInputText>File</FileInputText>
                          <FileInputBox>
                            <span>PDF File</span>
                            <FileUploadLabel>
                              <FileInput
                                type="file"
                                multiple
                                onChange={(e) =>
                                  handleFileUpload(question.id, e)
                                }
                                disabled={isTaskCompleted}
                                accept=".pdf"
                              />
                              Upload file <IconPaperclip size={16} />
                            </FileUploadLabel>
                          </FileInputBox>
                          <FileLimit>
                            {taskAnswers[currentTask.id]?.answers?.[question.id]
                              ?.files?.length || 0}{" "}
                            / {question.max_files} files
                          </FileLimit>
                        </FileInputContainer>
                      ) : null}

                      {(isTaskCompleted
                        ? solvedAnswer?.answer_files?.length > 0
                        : taskAnswers[currentTask.id]?.answers?.[question.id]
                            ?.files?.length > 0) && (
                        <FileList isCompleted={isTaskCompleted}>
                          {(isTaskCompleted
                            ? solvedAnswer?.answer_files
                            : taskAnswers[currentTask.id].answers[question.id]
                                .files
                          ).map((file, idx) => (
                            <FileItem key={idx}>
                              <FileInfo>
                                <IconFile size={20} />
                                <span>{file.name || `File ${idx + 1}`}</span>
                              </FileInfo>
                              {isTaskCompleted ? (
                                <DownloadButton
                                  as="a"
                                  href={
                                    file.file_url ||
                                    `data:application/pdf;base64,${file.file}`
                                  }
                                  download
                                  target="_blank"
                                  rel="noreferrer noopener"
                                >
                                  View file <IconDownload size={16} />
                                </DownloadButton>
                              ) : (
                                <DeleteButton
                                  onClick={() =>
                                    handleDeleteFile(question.id, idx)
                                  }
                                  title="Delete file"
                                >
                                  ×
                                </DeleteButton>
                              )}
                            </FileItem>
                          ))}
                        </FileList>
                      )}
                    </FileUploadWrapper>
                  )}
                </EditorWrapper>
              </QuestionWrapper>
            );
          })}
        </QuestionSection>
      )}

      {/* Navigation and Complete Buttons */}
      <TaskNavigation>
        <NavButton onClick={handlePrevTask} disabled={currentTaskIndex === 0}>
          Previous Task
        </NavButton>
        {!isTaskCompleted && !xs && (
          <CompleteButton
            onClick={handleCompleteClick}
            disabled={isTaskCompleted || isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Complete Task"}
          </CompleteButton>
        )}
        <NavButton
          onClick={handleNextTask}
          disabled={currentTaskIndex === tasks?.length - 1}
        >
          Next Task
        </NavButton>
      </TaskNavigation>
      {!isTaskCompleted && xs && (
        <CompleteButton
          onClick={handleTaskComplete}
          disabled={isTaskCompleted || isSubmitting}
        >
          {isSubmitting ? "Submitting..." : "Complete Task"}
        </CompleteButton>
      )}

      {/* Update the confirmation modal */}
      <Modal
        opened={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        title={
          <Box mb={4}>
            <Text size="xl" weight={600} color="#1F2937">
              {t("confirmModal.title")}
            </Text>
            <Text size="sm" color="#6B7280" mt={4}>
              {t("confirmModal.taskOf")
                .replace("{current}", currentTaskIndex + 1)
                .replace("{total}", tasks?.length)}
            </Text>
          </Box>
        }
        styles={{
          modal: {
            padding: "32px",
            borderRadius: "12px",
          },
          header: {
            marginBottom: "24px",
            padding: 0,
          },
          title: {
            fontSize: "20px",
          },
          close: {
            color: "#6B7280",
            "&:hover": {
              backgroundColor: "#F3F4F6",
            },
          },
        }}
        size="md"
        centered
      >
        <Box>
          {/* Progress Section */}
          <Box
            sx={{
              backgroundColor: "#F9FAFB",
              border: "1px solid #E5E7EB",
              borderRadius: "10px",
              padding: "16px",
              marginBottom: "24px",
            }}
          >
            <Text size="sm" color="#4B5563" weight={500} mb={12}>
              {t("confirmModal.taskProgress")}
            </Text>
            <Group spacing="xl">
              <Box>
                <Text size="sm" color="#6B7280" mb={4}>
                  {t("confirmModal.questions")}
                </Text>
                <Text size="lg" weight={600} color="#1F2937">
                  {currentTask?.task_question?.length || 0}
                </Text>
              </Box>
              <Box>
                <Text size="sm" color="#6B7280" mb={4}>
                  {t("confirmModal.answered")}
                </Text>
                <Text
                  size="lg"
                  weight={600}
                  color={
                    Object.keys(taskAnswers[currentTask?.id]?.answers || {})
                      .length === currentTask?.task_question?.length
                      ? "#059669"
                      : "#DC2626"
                  }
                >
                  {
                    Object.keys(taskAnswers[currentTask?.id]?.answers || {})
                      .length
                  }{" "}
                  / {currentTask?.task_question?.length}
                </Text>
              </Box>
            </Group>
          </Box>

          {/* Warning Section */}
          <Box
            sx={{
              backgroundColor: "#FFFBEB",
              border: "1px solid #FEF3C7",
              borderRadius: "10px",
              padding: "16px",
              marginBottom: "24px",
            }}
          >
            <Group spacing="sm" mb={12}>
              <Box
                sx={{
                  backgroundColor: "#FEF3C7",
                  borderRadius: "50%",
                  width: "24px",
                  height: "24px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#B45309",
                  fontWeight: "bold",
                }}
              >
                !
              </Box>
              <Text size="sm" weight={600} color="#92400E">
                {t("confirmModal.warningTitle")}
              </Text>
            </Group>
            <Box
              sx={{
                borderLeft: "2px solid #FDE68A",
                paddingLeft: "12px",
                marginLeft: "12px",
              }}
            >
              <Text size="sm" color="#92400E" lh={1.6}>
                • {t(`confirmModal.warningPoints.0`)}
                <br />• {t(`confirmModal.warningPoints.1`)}
                <br />• {t(`confirmModal.warningPoints.2`)}
                <br />• {t(`confirmModal.warningPoints.3`)}
              </Text>
            </Box>
          </Box>

          {/* Confirmation Section */}
          <Box mb={32}>
            <Text size="sm" color="#4B5563" weight={500}>
              {t("confirmModal.confirmText")}
            </Text>
          </Box>

          {/* Action Buttons */}
          <Group position="right" spacing="sm">
            <Button
              variant="default"
              onClick={() => setShowConfirmModal(false)}
              sx={{
                padding: "8px 20px",
                height: "40px",
                color: "#374151",
                borderColor: "#E5E7EB",
                "&:hover": {
                  backgroundColor: "#F9FAFB",
                },
              }}
            >
              {t("confirmModal.cancel")}
            </Button>
            <Button
              onClick={handleTaskComplete}
              loading={isSubmitting}
              sx={{
                padding: "8px 20px",
                height: "40px",
                backgroundColor: "#059669",
                "&:hover": {
                  backgroundColor: "#047857",
                },
                "&:disabled": {
                  backgroundColor: "#A7F3D0",
                },
              }}
            >
              {isSubmitting ? (
                t("confirmModal.submitting")
              ) : (
                <Group spacing={8}>
                  <span>{t("confirmModal.completeTask")}</span>
                  {Object.keys(taskAnswers[currentTask?.id]?.answers || {})
                    .length === currentTask?.task_question?.length && (
                    <span style={{ fontSize: "18px" }}>✓</span>
                  )}
                </Group>
              )}
            </Button>
          </Group>
        </Box>
      </Modal>
    </TaskWrapper>
  );
};

export default TaskSection;

const TaskWrapper = styled.div`
  max-width: 90%;
  margin: 0 auto;
  @media (max-width: 768px) {
    max-width: 90%;
    margin: 0 auto;
  }
`;

const TaskHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`;

const TaskNavigation = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  margin-top: 32px;
  @media (max-width: 408px) {
    align-items: center;
    justify-content: center;
  }
`;

const NavButton = styled.button`
  padding: 8px 16px;
  border-radius: 8px;
  background-color: ${(props) => (props.disabled ? "#E5E7EB" : "#029199")};
  color: ${(props) => (props.disabled ? "#9CA3AF" : "white")};
  border: none;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  transition: all 0.3s ease;

  &:hover:not(:disabled) {
    background-color: #017980;
  }
  @media (max-width: 408px) {
    width: 100%;
  }
`;

const QuizTitle = styled.h2`
  font-size: 24px;
  font-weight: 600;
  color: #202224;
  margin-bottom: 32px;
`;

const QuizSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

const QuizQuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const QuestionWrapper = styled.div`
  margin-bottom: 32px;
`;

const Question = styled.div`
  display: flex;
  gap: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #202224;
  margin-bottom: 16px;
`;

const OptionsGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const RadioInput = styled.input`
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid ${(props) => (props.checked ? "#029199" : "#E5E7EB")};
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
  flex-shrink: 0;
  margin-left: 8px;

  &:checked {
    background: #029199;
    border-color: #029199;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
    }
  }

  &:hover {
    border-color: #029199;
  }
`;

const LinkPreview = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #575757;
`;

const QuestionSection = styled.div``;

const EditorWrapper = styled.div`
  .mantine-RichTextEditor-root {
    border-radius: 8px;
    overflow: hidden;
  }
`;

const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px;
  border: 1px solid ${(props) => (props.$isSelected ? "#029199" : "#E5E7EB")};
  border-radius: 8px;
  background-color: ${(props) => (props.$isSelected ? "#F1FAFA" : "white")};
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;
  opacity: ${(props) => (props.$isCompleted ? 0.7 : 1)};
  cursor: ${(props) => (props.$isCompleted ? "default" : "pointer")};

  &:hover {
    background-color: ${(props) => (props.$isSelected ? "#F1FAFA" : "#F9FAFB")};
    border-color: #029199;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(2, 145, 153, 0.1);
  }

  &:active {
    transform: translateY(0);
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: ${(props) => (props.$isSelected ? "300%" : "0%")};
    height: 300%;
    background: radial-gradient(
      circle,
      rgba(2, 145, 153, 0.1) 0%,
      rgba(2, 145, 153, 0) 70%
    );
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    transform: translate(-50%, -50%);
    opacity: ${(props) => (props.$isSelected ? "1" : "0")};
  }

  ${(props) =>
    props.$isSelected &&
    `
    animation: selectPulse 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  `}

  @keyframes selectPulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.02);
    }
    100% {
      transform: scale(1);
    }
  }
`;

const FileSection = styled.div`
  margin: 24px 0;
  border-radius: 8px;
`;

const FileTitle = styled.h4`
  font-size: 16px;
  color: #364152;
  font-weight: 400;
  margin-bottom: 10px;
`;

const FileList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: ${(props) => (props.isCompleted ? "16px" : "0")};
`;

const FileItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
  background: white;
  border-radius: 6px;
  border: 1px solid #e5e7eb;
  background-color: #f7f7f7;
`;

const FileInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #575757;
`;

const DownloadButton = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: #f8fafc;
  color: #1f2a37;
  border-radius: 6px;
  text-decoration: none;
  font-size: 14px;
  transition: all 0.3s ease;
  border: 1px solid #cdd5df;

  &:hover {
    background: #f1f5f9;
  }
`;

const QuestionFileWrapper = styled.div`
  margin: 16px 0;
  max-width: 100%;
  display: flex;
  justify-content: center;
`;

const QuestionImage = styled.img`
  max-width: 100%;
  max-height: 400px;
  object-fit: contain;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.02);
  }
`;

const CompleteButton = styled(NavButton)`
  background-color: ${(props) => {
    if (props.disabled) return "#A5D6A7";
    return "#4CAF50";
  }};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.7 : 1)};

  &:hover:not(:disabled) {
    background-color: #45a049;
  }
  @media (max-width: 408px) {
    width: 100%;
    margin-top: 10px;
  }
`;

const FileUploadWrapper = styled.div`
  margin-top: 16px;
`;

const FileInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const FileInputText = styled.div`
  font-size: 14px;
  color: #364152;
`;

const FileInputBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  background: #fff;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  color: #6b7280;
  font-size: 14px;
`;

const FileUploadLabel = styled.label`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  background: transparent;
  color: #374151;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  border: 1px solid #d1d5db;
  transition: all 0.2s ease;

  &:hover {
    background-color: #f9fafb;
  }
`;

const FileInput = styled.input`
  display: none;
`;

const FileLimit = styled.div`
  font-size: 12px;
  color: #6b7280;
  margin-top: 4px;
`;

const DeleteButton = styled.button`
  background: none;
  border: none;
  color: #ef4444;
  font-size: 20px;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;

  &:hover {
    background-color: #fee2e2;
  }
`;
