import React, { useEffect, useState, useContext, useMemo } from "react";
import styled from "styled-components";
import competitionDetailBg from "../../Assets/SVGs/competition/competition.png";
import {
  IconInfoCircle,
  IconShare2,
  IconExternalLink,
  IconCalendar,
  IconClock,
  IconHourglassHigh,
  IconCurrencyManat,
  IconAlertCircle,
  IconChecklist,
} from "@tabler/icons-react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Accordion, Avatar, Modal, List, Text, Group } from "@mantine/core";
import relatedCourse from "../../Assets/SVGs/competition/related-course.png";
import TaskSection from "../../features/Competition/components/TaskSection";
import { useGetCompetition } from "../../features/Competition/hooks/useGetCompetition";
import { AppContext } from "../../Helpers/Context";
import ResultsSection from "../../features/Competition/components/ResultsSection";
import MyResultsSection from "../../features/Competition/components/MyResultsSection";
import { useQuery } from "@tanstack/react-query";
import axios from "../../Helpers/Axios";
import { getSlugAndIdFromPath } from "../../features/Competition/utils";
import CompetitionDetailSkeleton from "../../features/Competition/components/skeletons/CompetitionDetailSkeleton";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mantine/hooks";
import { faSlash } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from 'react-helmet-async';  // react-helmet əvəzinə react-helmet-async

const NavigationItem = ({ id, label, isActive, onClick }) => (
  <NavItem onClick={() => onClick(id)} $isActive={isActive}>
    {label}
  </NavItem>
);

const relatedCourses = [
  {
    id: 1,
    title: "0-dan SÜNİ İNTELEKT və DATA ANALİZİ",
    image: relatedCourse,
  },
  {
    id: 2,
    title: "MÜHƏNDİSLİK Kampı: Unity/C#",
    image: relatedCourse,
  },
  {
    id: 3,
    title: "Frontend Development Bootcamp",
    image: relatedCourse,
  },
  {
    id: 4,
    title: "Backend Development with Node.js",
    image: relatedCourse,
  },
];
const CompetitionDetail = () => {
  const { pathname } = useLocation();
  const { slug, id } = getSlugAndIdFromPath(pathname);
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState("description");
  const [isPaid, setIsPaid] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [isHackathon, setIsHackathon] = useState(false);
  const [showTasks, setShowTasks] = useState(false);

  const [showRulesModal, setShowRulesModal] = useState(false);
  const [isEnded, setIsEnded] = useState(false);
  const { user } = useContext(AppContext);
  const [untilStart, setUntilStart] = useState(null);
  const [showTimeWarningModal, setShowTimeWarningModal] = useState(false);
  const [showResultsPendingModal, setShowResultsPendingModal] = useState(false);
  const [showStartedModal, setShowStartedModal] = useState(false);
  const [showEndingSoonModal, setShowEndingSoonModal] = useState(false);
  const [hasShownEndWarning, setHasShownEndWarning] = useState(() => {
    const stored = localStorage.getItem(`competition_${id}_end_warning`);
    return stored ? JSON.parse(stored) : false;
  });
  const [timeRemaining, setTimeRemaining] = useState(null);

  const { data: studentResults } = useQuery({
    queryKey: ["studentResults", id],
    queryFn: async () =>
      await axios
        .get(`/olympiad/${slug}-${id}/students/`)
        .then((res) => res.data),
  });

  const { data: myResults } = useQuery({
    queryKey: ["myResults", id],
    queryFn: async () =>
      await axios
        .get(`/olympiad/${slug}-${id}/student-result/`)
        .then((res) => res.data),
  });

  const {data :relatedCourses} = useQuery({
    queryKey: ["relatedCourses", id],
    queryFn: async () =>
      await axios
        .get(`/olympiad/${slug}-${id}/related-courses/`)
        .then((res) => res.data),
  });


  const [isShowResults, setIsShowResults] = useState(false);


  const size1280 = useMediaQuery("(max-width: 1280px)");
  const size990 = useMediaQuery("(max-width: 990px)");


  const {
    data: competition,
    isLoading,
    isError,
    refetch,
  } = useGetCompetition(slug, id);
  useEffect(() => {
    if (competition?.is_joined) {
      setIsPaid(true);
      setShowStartedModal(false);
    }
  }, [competition]);

  const { t } = useTranslation();

  const navigationItems = useMemo(
    () => [
      ...(!isEnded && showTasks
        ? [{ id: "tasks", label: t("competitionDetail.task") }]
        : []),
      ...(isEnded
        ? [
            { id: "results", label: t("competitionDetail.results") },
            { id: "myResults", label: t("competitionDetail.myResults") },
          ]
        : []),
      { id: "description", label: t("competitionDetail.description") },
      { id: "requirements", label: t("competitionDetail.requirements") },
      { id: "mentors", label: t("competitionDetail.mentors") },
      {
        id: "hostingOrganization",
        label: t("competitionDetail.hostingOrganization"),
      },
      { id: "location", label: t("competitionDetail.location") },
      { id: "dateAndTime", label: t("competitionDetail.dateAndTime") },
    ],
    [showTasks, isEnded, t]
  );

  
  useEffect(() => {
    if (competition && competition?.is_completed) {
      setIsShowResults(true);
      setActiveSection("results");
    } else {
      setActiveSection("description");
    }
  }, [competition]);
  const scrollToSection = (sectionId) => {
    // Show results pending modal if competition is not completed
    if (
      ["results", "myResults"].includes(sectionId) && 
      !competition?.is_completed
    ) {
      setShowResultsPendingModal(true);
      return;
    }

    // Show results pending modal if results aren't available
    if (
      sectionId === "results" &&
      (!studentResults || studentResults.length === 0)
    ) {
      setShowResultsPendingModal(true);
      return;
    }

    // Show results pending modal if my results aren't available
    if (sectionId === "myResults" && !myResults) {
      setShowResultsPendingModal(true);
      return;
    }

    // Handle tasks section separately
    if (sectionId === "tasks") {
      setActiveSection(sectionId);
      return;
    }

    // Normal section scrolling behavior
    const element = document.getElementById(sectionId);
    if (element) {
      setActiveSection(sectionId);
      const elementPosition =
        element.getBoundingClientRect().top + window.pageYOffset;
      const offset = 200;
      const additionalOffset = sectionId === "partners" ? -80 : 0;

      window.scrollTo({
        top: elementPosition - offset - additionalOffset,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + 300; // Header və navigation-ın hündürlüyünü nəzərə alırıq
      let currentSection = null;
      let minDistance = Infinity;

      // Bütün section-ları yoxlayırıq
      for (const { id } of navigationItems) {
        const element = document.getElementById(id);
        if (element) {
          const rect = element.getBoundingClientRect();
          const elementTop = window.scrollY + rect.top;
          const elementBottom = elementTop + element.offsetHeight;
          const distance = Math.abs(scrollPosition - elementTop);

          // Scroll position section-un yuxarı və aşağı sərhədləri arasındadırsa
          if (scrollPosition >= elementTop - 300 && scrollPosition <= elementBottom) {
            if (distance < minDistance) {
              minDistance = distance;
              currentSection = id;
            }
          }
        }
      }

      // Əgər aktiv section tapılıbsa, onu set edirik
      if (currentSection) {
        setActiveSection(currentSection);
      }
    };

    window.addEventListener("scroll", handleScroll);
    // Component unmount olduqda event listener-i təmizləyirik
    return () => window.removeEventListener("scroll", handleScroll);
  }, [navigationItems]);

  useEffect(() => {
    if (competition) {
      setTasks(competition.olympiad_tasks);
      setIsHackathon(competition.is_hackathon);
    }
  }, [competition]);
  // const start_date  = "2025-02-04T15:00:00Z"
  // const end_date = "2025-02-04T15:06:00Z"
  // const start_time = "15:00"
  // const end_time = "15:06"

  useEffect(() => {
    if (!competition?.olympiad_dates?.[0]) return;

    const checkTime = () => {
      try {
        const now = new Date();

        // Parse start date and time correctly
        const [startDateOnly] = competition.olympiad_dates[0].start_date.split('T');
        const startDate = new Date(`${startDateOnly}T${competition.olympiad_dates[0].start_time}:00`);

        // Parse end date and time correctly
        const [endDateOnly] = competition.olympiad_dates[0].end_date.split('T');
        const endDate = new Date(`${endDateOnly}T${competition.olympiad_dates[0].end_time}:00`);

        // Calculate time differences in minutes
        const minutesUntilStart = Math.ceil((startDate - now) / (1000 * 60));
        const minutesUntilEnd = Math.ceil((endDate - now) / (1000 * 60));
        const secondsUntilStart = Math.ceil((startDate - now) / 1000);
        const secondsUntilEnd = Math.ceil((endDate - now) / 1000);

        // Update time remaining display

        if (minutesUntilEnd > 0 && minutesUntilStart <= 0) {
          setTimeRemaining({
            minutes: Math.floor(secondsUntilEnd / 60),
            seconds: secondsUntilEnd % 60,
            isLastTwoMinutes: minutesUntilEnd <= 2,
          });
        } else {
          setTimeRemaining(null);
        }

        // Show rules modal 2 minutes before start
        if (minutesUntilStart <= 2 && minutesUntilStart > 0) {
          setShowRulesModal(true);
          setShowTasks(false);
          setUntilStart(secondsUntilStart);
        }

        // Competition starts
        if (minutesUntilStart <= 0 && minutesUntilEnd > 0) {
          setShowRulesModal(false);
          if (!isPaid) {
            setShowStartedModal(true);
          } else {
            setShowTasks(true);
            setShowStartedModal(false);
          }
        }

        // Show warning 2 minutes before end
        if (minutesUntilEnd === 2 && !hasShownEndWarning) {
          setShowEndingSoonModal(true);
          setHasShownEndWarning(true);
          localStorage.setItem(`competition_${id}_end_warning`, "true");
        }

        // Competition ends
        if (minutesUntilEnd <= 0) {
          setIsEnded(true);
          setShowTasks(false);
          if (activeSection === "tasks") {
            setActiveSection("results");
          }
          localStorage.removeItem(`competition_${id}_end_warning`);
        }
      } catch (error) {
        console.error("Error in checkTime:", error);
      }
    };

    checkTime();
    const interval = setInterval(checkTime, 1000);
    return () => clearInterval(interval);
  }, [competition?.olympiad_dates, isPaid, id, hasShownEndWarning]);
  const formatTimeRemaining = (seconds) => {
    if (!seconds || seconds <= 0) return t("competitionDetail.startingSoon");



    try {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;

      return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
    } catch (error) {
      console.error("Error formatting time:", error);
      return t("competitionDetail.startingSoon");
    }
  };


  if (isLoading) {
    return <CompetitionDetailSkeleton />;
  }

  if (isError) return <div>Error</div>;

  const handleShare = () => {
    navigator.clipboard.writeText(window.location.href);
    // You might want to add a toast notification here
  };

  const handleGetTickets = () => {
    navigate(`/competition/${slug}-${id}/payment`);
  };

  const handleCloseEndWarning = () => {
    setShowEndingSoonModal(false);
    setHasShownEndWarning(true);
    localStorage.setItem(`competition_${id}_end_warning`, "true");
  };

  return (
    <StyledCompetitionDetail>
      <Helmet>
      <title>{competition?.name || 'Competition Detail'} | {competition?.is_hackathon ? 'Hackathon' : 'Olympiad'}</title>
      <meta name="description" content={competition?.content?.slice(0, 155) || 'Join our exciting competition and showcase your skills'} />
        <meta property="og:title" content={`${competition?.name || 'Competition Detail'} | ${competition?.is_hackathon ? 'Hackathon' : 'Olympiad'}`} />


        <meta property="og:description" content={competition?.content?.slice(0, 155) || 'Join our exciting competition and showcase your skills'} />
        <meta property="og:image" content={competition?.cover_image_url || ''} />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <Banner backgroundImage={competition?.cover_image_url}>
        <BannerInner backgroundImage={competition?.cover_image_url}></BannerInner>
        <BannerTitle>{competition?.name}</BannerTitle>
      </Banner>
      <Navigation>
        <nav>
          {navigationItems.map((item) => (
            <NavigationItem
              key={item.id}
              id={item.id}
              label={item.label}
              isActive={activeSection === item.id}
              onClick={scrollToSection}
            />
          ))}
          {timeRemaining && (
            <TimeRemaining $isLastTwoMinutes={timeRemaining.isLastTwoMinutes}>
              {t("competitionDetail.timeRemaining")}{" "}
              {String(timeRemaining.minutes).padStart(2, "0")}:
              {String(timeRemaining.seconds).padStart(2, "0")}
            </TimeRemaining>
          )}
        </nav>
      </Navigation>

      <GridContainer isPaid={isPaid}>
        {!isEnded && activeSection === "tasks" && (
          <Section id="tasks">
            {showTasks ? (
              <TaskSection
                isHackathon={isHackathon}
                competitionId={id}
                slug={slug}
                tasks={tasks}
              />
            ) : showRulesModal ? (
              <CountdownWrapper>
                <IconClock size={48} color="#029199" />
                <Text size="xl" weight={600} color="#364152">
                  {t("competitionDetail.competitionStartsIn")}
                </Text>
                <CountdownTimer>
                </CountdownTimer>
              </CountdownWrapper>
            ) : null}
          </Section>
        )}

        {(activeSection !== "tasks" || isEnded) && (
          <ContentWrapper>
            {isShowResults && (
              <>
                {studentResults && studentResults.length > 0 && (
                  <Section id="results" $isActive={activeSection === "results"}>
                    <SectionTitle
                      className={activeSection === "results" ? "active" : ""}

                    >
                      {t("competitionDetail.results")}
                    </SectionTitle>
                    <ResultsSection
                      dummyResults={studentResults}
                      competitionId={id}
                    />
                  </Section>
                )}

                {isShowResults && isPaid && (
                  <Section
                    id="myResults"
                    $isActive={activeSection === "myResults"}

                  >
                    <SectionTitle
                      style={{ marginTop: "24px" }}
                      className={activeSection === "myResults" ? "active" : ""}
                    >
                      {t("competitionDetail.myResults")}
                    </SectionTitle>
                    <MyResultsSection
                      myScore={myResults}
                      slug={slug}
                      competitionId={id}
                      userId={user?.id}
                    />
                  </Section>
                )}
              </>
            )}

            <Section
              $isActive={activeSection === "description"}
              id="description"
            >
              <SectionTitle
                className={activeSection === "description" ? "active" : ""}
              >
                {t("competitionDetail.description")}
              </SectionTitle>
              <LevelBadge>
                <Diamond />
                {t("competitionDetail.level")} - {competition?.olympiad_level}
              </LevelBadge>
              <div className="description-content" style={{color:"#697586"}} dangerouslySetInnerHTML={{ __html: competition?.content }} />
            </Section>


            <Section
              $isActive={activeSection === "requirements"}
              id="requirements"
            >
              <SectionTitle
                className={activeSection === "requirements" ? "active" : ""}
              >
                {t("competitionDetail.requirements")}
              </SectionTitle>
              <Group mb={24} align="center">
                {
                  competition?.skills_list?.map((skill) => (
                    <SkillBadge key={skill.id}>

                    {skill.skill}
                  </SkillBadge>
                ))
              }
              </Group>
              <div
              className="description-content"
                style={{color:"#697586"}}
                dangerouslySetInnerHTML={{ __html: competition?.skills_text }}


              />
            </Section>

            <Section $isActive={activeSection === "mentors"} id="mentors">
              <SectionTitle
                className={activeSection === "mentors" ? "active" : ""}
              >
                {t("competitionDetail.mentors")}
              </SectionTitle>
              {competition?.menthors_list?.map((mentor) => (
                <MentorCard key={mentor.id}>
                  <Avatar
                    radius="100%"
                    size={size990 ? 60 : size1280 ? 90 : 120}
                    src={mentor.image}
                    alt={mentor.name}
                  />
                  <MentorInfo>
                    <MentorName>
                      {mentor.first_name} {mentor.last_name}
                    </MentorName>
                    <Text className="description-content" color="#697586">
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at its layout. The point of using Lorem Ipsum is that it
                      has a more-or-less normal distribution of letters, as
                      opposed to using 'Content here
                    </Text>
                  </MentorInfo>
                </MentorCard>
              ))}
            </Section>

            <Section
              $isActive={activeSection === "hostingOrganization"}
              id="hostingOrganization"
            >
              <SectionTitle
                className={
                  activeSection === "hostingOrganization" ? "active" : ""
                }
              >
                {t("competitionDetail.hostingOrganization")}
              </SectionTitle>
              <OrganizationCard>
                <Avatar
                  radius="100%"
                  size={size990 ? 60 : size1280 ? 90 : 120}
                  src={competition?.company_profile?.profile_image}
                  alt="company-logo"
                />
                <OrganizationInfo>
                  <OrganizationName>
                    {competition?.company_profile?.first_name}{" "}
                    {competition?.company_profile?.last_name}
                  </OrganizationName>
                  <Text className="description-content" color="#697586">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using 'Content here
                  </Text>
                </OrganizationInfo>
              </OrganizationCard>
            </Section>

            <Section $isActive={activeSection === "location"} id="location">
              <SectionTitle
                className={activeSection === "location" ? "active" : ""}
              >
                {t("competitionDetail.location")}
              </SectionTitle>
              <OnlineBadgeWrapper>
                <OnlineBadge>
                  <span>
                    {competition?.olympiad_type_output?.name.includes("Online") ? (
                      <>
                        {t('competitionDetail.online')}{" "}
                        <a className="description-content" href={competition?.link} target="_blank" rel="noreferrer noopener">
                          <IconExternalLink size={14} color="#029199" />
                        </a>
                      </>

                    ) : competition?.olympiad_type_output?.name.includes("Hybrid") ? (
                      <>
                        {t('competitionDetail.hybrid')} - {competition?.location}{" "}
                        <a className="description-content" href={competition?.link} target="_blank" rel="noreferrer noopener">
                          <IconExternalLink size={14} color="#029199" />
                        </a>

                      </>
                    ) : (
                      <div className="description-content">
                        {t('competitionDetail.offline')} - {competition?.location}
                      </div>
                    )}
                  </span>
                  <TooltipWrapper>
                    <IconInfoCircle 
                      style={{ marginTop: "2px" }} 
                      fill="#FFA523" 
                      color="#fff" 
                      size={24} 
                    />
                    <Tooltip>{t('competitionDetail.tooltipText')}</Tooltip>
                  </TooltipWrapper>
                </OnlineBadge>
              </OnlineBadgeWrapper>
            </Section>

            <Section
              $isActive={activeSection === "dateAndTime"}
              id="dateAndTime"
            >
              <SectionTitle
                className={activeSection === "dateAndTime" ? "active" : ""}
              >
                {t("competitionDetail.dateAndTime")}
              </SectionTitle>
              <DateTimeInfo>
                <DateTimeItem>
                  <IconCalendar size={20} stroke={2} color="#029199" />
                  {new Date(
                    competition?.olympiad_dates[0]?.start_date
                  ).toLocaleDateString("en-GB",{
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })}
                </DateTimeItem>
                <DateTimeItem>
                  <IconClock size={20} stroke={2} color="#029199" />
                  {competition?.olympiad_dates[0]?.start_time}
                </DateTimeItem>
                <DateTimeItem>
                  <IconHourglassHigh size={20} stroke={2} color="#029199" />
                  {(() => {
                    const start = new Date(
                      `2000-01-01T${competition?.olympiad_dates[0]?.start_time}`
                    );
                    const end = new Date(
                      `2000-01-01T${competition?.olympiad_dates[0]?.end_time}`
                    );
                    const diff = (end - start) / (1000 * 60); // minutes difference
                    const hours = Math.floor(diff / 60);
                    const minutes = diff % 60;
                    return `${hours} hours ${minutes} minute`;
                  })()}
                </DateTimeItem>
              </DateTimeInfo>
            </Section>
          </ContentWrapper>
        )}
        {!isPaid && !isEnded && (
          <RightSideWrapper>
            <PriceSection>
              <PriceInfo>
                <PriceLabel>{t("competitionDetail.price")}</PriceLabel>
                <PriceAmount>
                  {competition?.price} <IconCurrencyManat size={18} />
                </PriceAmount>
              </PriceInfo>
              <Box className="price-buttons">
                <Button onClick={handleGetTickets}>
                  {t("competitionDetail.getTickets")}
                </Button>
                <ShareButton onClick={handleShare}>
                  <IconShare2 size={20} />
                  {t("competitionDetail.shareOlympiad")}
                </ShareButton>
              </Box>
            </PriceSection>

            <RelatedCoursesSection>
              <Accordion defaultValue="courses">
                <Accordion.Item value="courses">
                  <StyledAccordionControl>
                    {t("competitionDetail.intensiveCourses")}
                  </StyledAccordionControl>
                  <Accordion.Panel>
                    <CoursesList>
                      <CoursesScrollArea>
                        {relatedCourses?.map((course) => (
                          <CourseItem key={course.id}>
                            <CourseImage
                              src={course?.bootcamp_image}
                              alt={course?.id}
                            />
                            <CourseInfo>
                              <CourseTitle>{course?.bootcamp_name}</CourseTitle>

                            </CourseInfo>
                          </CourseItem>

                        ))}
                      </CoursesScrollArea>
                      <ShowMoreButton onClick={() => navigate("/bootcamps")}>
                        {t("competitionDetail.showMore")}
                      </ShowMoreButton>
                    </CoursesList>
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            </RelatedCoursesSection>
          </RightSideWrapper>
        )}
      </GridContainer>

      {/* Starting Soon Modal */}

      <Modal
        opened={showRulesModal}
        onClose={() => {}}
        closeOnClickOutside={false}
        closeOnEscape={false}
        withCloseButton={false}
        size="lg"
        centered
      >
        <ModalContent>
          <ModalHeader>
            <IconAlertCircle size={48} color="#029199" />
            <Text size="xl" weight={600} mb={20}>
              {t("competitionDetail.competitionStartingSoon")}
            </Text>
          </ModalHeader>

          <Text size="md" mb={30} color="#364152">
            {t("competitionDetail.pleaseReviewRules")}
          </Text>

          <List
            spacing="md"
            size="md"
            center
            icon={<IconChecklist size={24} color="#029199" />}
          >
            <List.Item>
              {t("competitionDetail.rules.rule1")}
            </List.Item>
            <List.Item>
              {t("competitionDetail.rules.rule2")}


            </List.Item>
            <List.Item>
              {t("competitionDetail.rules.rule3")}

            </List.Item>
            <List.Item>{t("competitionDetail.rules.rule4")}</List.Item>
            <List.Item>
              {t("competitionDetail.rules.rule5")}
            </List.Item>

            <List.Item>
              {t("competitionDetail.rules.rule6")}
            </List.Item>
          </List>


          <CountdownText>
            {t("competitionDetail.competitionStartsIn")}{" "}
            {formatTimeRemaining(untilStart)}
          </CountdownText>
        </ModalContent>
      </Modal>

      {/* Time Warning Modal */}
      <Modal
        opened={showTimeWarningModal}
        onClose={() => setShowTimeWarningModal(false)}
        size="md"
        centered
        withCloseButton={false}
      >
        <ModalContent>
          <ModalHeader>
            <IconAlertCircle size={48} color="#FFA523" />
            <ModalTitle>{t("competitionDetail.timeIsAlmostUp")}</ModalTitle>
          </ModalHeader>
          <ModalText>
            {t("competitionDetail.timeWarning")}{" "}
          </ModalText>
          <ModalButton onClick={() => setShowTimeWarningModal(false)}>
            {t("competitionDetail.continueWorking")}
          </ModalButton>
        </ModalContent>
      </Modal>

      {/* Already Started Modal */}
      {showStartedModal && (
        <Modal
          opened={showStartedModal}
          onClose={() => setShowStartedModal(false)}
          size="md"
          centered
        >
          <ModalContent>
            <ModalHeader>
              <IconAlertCircle size={48} color="#FFA523" />
              <ModalTitle>
                {t("competitionDetail.competitionInProgress")}
              </ModalTitle>
            </ModalHeader>
            <ModalText>
              {t("competitionDetail.registerToPay")}
            </ModalText>
            <Button onClick={handleGetTickets}>

              {t("competitionDetail.registerNow")}
            </Button>
          </ModalContent>
        </Modal>
      )}

      {/* Ending Soon Modal */}
      <Modal
        opened={showEndingSoonModal}
        onClose={handleCloseEndWarning}
        size="md"
        centered
      >
        <ModalContent>
          <ModalHeader>
            <IconAlertCircle size={48} color="#FFA523" />
            <ModalTitle>
              {t("competitionDetail.twoMinutesRemaining")}
            </ModalTitle>
          </ModalHeader>
          <ModalText>
            {t("competitionDetail.completeAllTasks")}{" "}
          </ModalText>
          <Button onClick={handleCloseEndWarning}>
            {t("competitionDetail.continueWorking")}
          </Button>
        </ModalContent>
      </Modal>

      {/* Results Pending Modal */}
      <Modal
        opened={showResultsPendingModal}
        onClose={() => setShowResultsPendingModal(false)}
        size="md"
        centered
      >
        <ModalContent>
          <ModalHeader>
            <IconHourglassHigh size={48} color="#029199" />
            <ModalTitle>
              {t("competitionDetail.resultsBeingProcessed")}
            </ModalTitle>
          </ModalHeader>
          <ModalText>
            {activeSection === "myResults"
              ? t(
                  "competitionDetail.personalResultsReview"
                )
              : t("competitionDetail.competitionResultsProcessing")}
          </ModalText>

          <Button onClick={() => setShowResultsPendingModal(false)}>
            {t("competitionDetail.close")}
          </Button>
        </ModalContent>
      </Modal>
    </StyledCompetitionDetail>
  );
};

export default CompetitionDetail;

const StyledCompetitionDetail = styled.div``;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.isPaid ? "1fr" : "2fr 1fr")};
  
  gap: 24px;
  @media (max-width: 990px) {
    grid-template-columns: 1fr;
  }
`;

const Banner = styled.div`
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: center;
  height: 362px;
  position: relative;
  padding: 40px 120px 0 120px;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(12, 8, 15, 0.4);
    backdrop-filter: blur(20px);
  }

  @media (max-width: 768px) {
    height: 200px;
    padding: 20px 20px 0 20px;
  }
`;

const BannerInner = styled.div`
  height: 100%;
  position: relative;
  background-image: url(${props => props.backgroundImage});
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  overflow: hidden;
`;

const BannerTitle = styled.h1`
  position: absolute;
  color: white;
  font-size: 36px;
  font-weight: 600;
  bottom: 40px;
  left: 40px;
  z-index: 99;

  @media (max-width: 768px) {
    font-size: 24px;
    bottom: 20px;
    left: 20px;
  }
`;

const Navigation = styled.div`
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 8px 0;
  position: sticky;
  top: 70px;
  z-index: 9;
  transition: top 0.3s ease-in-out;

  @media (max-width: 768px) {
    top: 50px;
    padding: 5px 0;
  }

  nav {
    max-width: 90%;
    margin: 0 auto;
    display: flex;
    gap: 40px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    @media (max-width: 768px) {
      gap: 24px;
      padding: 0 16px;
      white-space: nowrap;

      &::after {
        content: "";
        padding-right: 16px;
      }
    }
  }
`;

const NavItem = styled.button`
  background: none;
  border: none;
  padding: 10px 0px;
  cursor: pointer;
  white-space: nowrap;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  transition: color 0.3s;
  color: ${(props) => (props.$isActive ? "#029199" : "#979797")};

  &:hover {
    color: #029199;
  }

  @media (max-width: 1280px) {
    font-size: 16px;
    line-height: 24px;
    padding: 8px 0;
  }
  @media (max-width: 990px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const ContentWrapper = styled.div`
  max-width: 85%;
  margin: 0 auto;
  padding: 40px 0;

  @media (max-width: 1280px) {
    max-width: 90%;
    padding: 30px 0;
  }
  @media (max-width: 990px) {
    max-width: 88%;
  }
`;

const Section = styled.section`
  margin-bottom: 12px;
  scroll-margin-top: 100px;
  padding: 20px 0;

  .description-content {
    color: #697586;
    font-size: 16px;
    @media (max-width: 1280px) {
      font-size: 14px;
    }
    @media (max-width: 990px) {
      font-size: 12px;
    }
  }


  &:first-child {
    padding: 0;
  }

  &:last-child {
    padding-bottom: 100px;
    @media (max-width: 768px) {
      padding-bottom: 0px;
    }
  }
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  font-weight: 600;
  color: #364152;
  margin-bottom: 24px;
  transition: color 0.3s;

  &.active {
    color: #029199;
  }

  @media (max-width: 1280px) {
    font-size: 20px;
    margin-bottom: 20px;
  }
  @media (max-width: 990px) {
    font-size: 18px;
    margin-bottom: 16px;
  }
`;

// constText = styled.p`
//   color: #575757;
//   font-size: 16px;
//   line-height: 1.5;
//   margin-bottom: 16px;
// `;

const LevelBadge = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
  color: #575757;
  font-size: 14px;
`;

const Diamond = styled.div`
  width: 10px;
  height: 14px;
  background: #029199;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  border-radius: 4px;
`;

const Language = styled.div`
  color: #029199;
  font-size: 14px;
  font-weight: 500;
`;

const MentorCard = styled.div`
  display: flex;
  gap: 24px;
  align-items: flex-start;

  @media (max-width: 1280px) {
    gap: 16px;
  }
  @media (max-width: 990px) {
    align-items: center;
  }
`;


const MentorImage = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;

  @media (max-width: 1280px) {
    width: 100px;
    height: 100px;
  }
`;

const MentorInfo = styled.div`
  flex: 1;
`;

const MentorName = styled.h3`
  font-size: 18px;
  font-weight: 600;
  color: #029199;
  margin-bottom: 12px;

  @media (max-width: 1280px) {
    font-size: 16px;
    margin-bottom: 8px;
  }
  @media (max-width: 990px) {
    font-size: 14px;
    margin-bottom: 6px;
  }
`;

const OrganizationCard = styled(MentorCard)``;

const OrganizationLogo = styled.img`
  width: 120px;
  height: 120px;
  object-fit: contain;

  @media (max-width: 1280px) {
    width: 100px;
    height: 100px;
  }
  @media (max-width: 990px) {
    width: 80px;
    height: 80px;
  }
`;

const OrganizationInfo = styled(MentorInfo)``;

const OrganizationName = styled(MentorName)`
    font-size: 18px;
  font-weight: 600;
  color: #029199;
  margin-bottom: 12px;

  @media (max-width: 1280px) {
    font-size: 16px;
    margin-bottom: 8px;
  }
  @media (max-width: 990px) {
    font-size: 14px;
    margin-bottom: 6px;
  }
`;

const OnlineBadgeWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 16px;
`;

const OnlineBadge = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 8px 0px;
  border-radius: 8px;
  color: #697586;
  font-size: 14px;
  font-weight: 500;
  
  @media (max-width: 576px) {
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
  }

  a {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
    
    &:hover {
      opacity: 0.8;
    }
  }
`;
const Tooltip = styled.div`
  position: absolute;
  bottom: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  background: #334155;
  color: white;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s;
  z-index: 1000;
  width: max-content;

  &::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: #334155 transparent transparent transparent;
  }

  @media (max-width: 576px) {
    white-space: normal;
    text-align: center;
    width: 200px;
    left: auto;
    right: -10px;
    transform: none;

    &::before {
      left: auto;
      right: 12px;
      transform: none;
    }
  }
`;
const TooltipWrapper = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-left: 8px;
  cursor: pointer;

  &:hover ${Tooltip} {
    opacity: 1;
    visibility: visible;
  }
`;



const DateTimeInfo = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
`;

const DateTimeItem = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #364152;
  font-size: 14px;
  @media (max-width: 990px) {
    font-size: 12px;
  }
`;


const RightSideWrapper = styled.div`
  position: sticky;
  top: 160px;
  height: fit-content;
  padding-top: 0px;
  width: 70%;
  margin: 10px auto 40px auto;
  z-index: 9;

  @media (max-width: 1280px) {
    width: 80%;
    margin: 10px auto 30px auto;
  }

  @media (max-width: 990px) {
    width: 95%;
    position: relative;
    z-index: 1;
    margin: 0 auto 40px auto;
    top: 0;
  }
`;

const PriceSection = styled.div`
  background: #fff;
  border-radius: 12px;
  margin-bottom: 24px;
  box-shadow: 0px 4px 15px 0px #0000001a;
  .price-buttons {
    padding: 14px 12px;
  }
`;

const PriceInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e7e7e7;
  padding: 19.6px 12px;
`;

const PriceLabel = styled.span`
  color: #202224;
  font-size: 18px;
  font-weight: 600;

  @media (max-width: 1280px) {
    font-size: 16px;
  }
  @media (max-width: 990px) {
    font-size: 14px;
  }
`;

const PriceAmount = styled.span`
  color: #202224;
  font-size: 18px;
  font-weight: 700;

  @media (max-width: 1280px) {
    font-size: 16px;
  }
  @media (max-width: 990px) {
    font-size: 14px;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 12px;
  background: #029199;
  color: white;
  border: none;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 12px;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.9;
  }
`;

const ShareButton = styled(Button)`
  background: #fff;
  color: #202224;
  border: 1px solid #e5e7eb;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &:hover {
    background: #f9fafb;
  }
`;

const RelatedCoursesSection = styled.div`
  background: #fff;
  border-radius: 12px;
  box-shadow: 0px 1px 8px 0px #0000001f;

  .mantine-Accordion-content {
    padding: 0;
  }

  .mantine-Accordion-item {
    border: none;
  }
`;

const StyledAccordionControl = styled(Accordion.Control)`
  padding: 16px;
  &:hover {
    background: transparent;
  }

  .mantine-Accordion-chevron {
    color: #029199;
  }
`;

const CoursesList = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const CoursesScrollArea = styled.div`
  max-height: 144px;
  overflow-y: scroll;
  padding: 0 16px;
  margin-right: 6px;

  /* Scrollbar styling */
  &::-webkit-scrollbar {
    width: 4px;
    background: transparent;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 14px;
  }

  &::-webkit-scrollbar-thumb {
    background: #029199;
    border-radius: 4px;
    min-height: 40px;
  }

  /* Firefox üçün scroll stili */
  scrollbar-width: thin;
  scrollbar-color: #029199 transparent;
`;

const CourseItem = styled.div`
  display: flex;
  gap: 12px;
  padding: 12px 0;
  height: 72px;
  border-radius: 8px;
  transition: background 0.2s;
  cursor: pointer;
  align-items: center;

  @media (max-width: 1280px) {
    height: 60px;
    gap: 8px;
    padding: 8px 0;
  }

  &:hover {
    background: #f9fafb;
  }
`;

const CourseImage = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 8px;
  object-fit: cover;

  @media (max-width: 1280px) {
    width: 40px;
    height: 40px;
  }
  @media (max-width: 990px) {
    width: 32px;
    height: 32px;
  }
`;

const CourseInfo = styled.div`
  flex: 1;
`;

const CourseTitle = styled.h3`
  font-size: 14px;
  font-weight: 400;
  color: #575757;
  margin: 0;
  line-height: 1.4;
  display: -webkit-box;

  @media (max-width: 1280px) {
    font-size: 13px;
    line-height: 1.3;
  }
  @media (max-width: 990px) {
    font-size: 12px;
  }
`;

const ShowMoreButton = styled.button`
  width: 100%;
  padding: 12px 16px;
  background: none;
  border: none;
  color: #029199;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  text-align: left;

  &:hover {
    background: #f9fafb;
  }
`;

const CountdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  gap: 20px;
  padding: 40px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`;

const CountdownTimer = styled.div`
  font-size: 48px;
  font-weight: 700;
  color: #029199;
  font-family: monospace;
  padding: 20px 40px;
  background: #f8fafc;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(2, 145, 153, 0.1);

  @media (max-width: 1280px) {
    font-size: 36px;
    padding: 16px 32px;
  }
  @media (max-width: 990px) {
    font-size: 24px;
    padding: 16px 24px;
  }
`;

const ModalContent = styled.div`
  padding: 32px;
  text-align: center;
`;

const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
`;

const ModalTitle = styled.h2`
  font-size: 24px;
  font-weight: 600;
  color: #202939;
  margin: 0;
`;

const ModalText = styled.p`
  font-size: 16px;
  color: #364152;
  margin-bottom: 32px;
  line-height: 1.5;
`;

const ModalButton = styled.button`
  width: 100%;
  padding: 12px;
  background: #029199;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: #027b82;
  }
`;

const InfoSection = styled.div`
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  background: #f8fafc;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
`;

const CountdownText = styled.div`
  margin-top: 30px;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: #029199;
  padding: 16px;
  background: #f1fafa;
  border-radius: 8px;
  font-family: monospace;
`;

const SkillBadge = styled.div`
  background: #EAFEFF;
  border-radius: 9px;
  border: 0.5px solid #029199;
  padding: 6px 14px;
  font-size: 14px;
  font-weight: 500;
  color: #029199;

`;

const TimeRemaining = styled.div`
  margin-left: auto;
  width: max-content;
  padding: 8px 16px;
  background: ${(props) => (props.$isLastTwoMinutes ? "#FEE2E2" : "#F3F4F6")};
  color: ${(props) => (props.$isLastTwoMinutes ? "#DC2626" : "#374151")};
  border-radius: 8px;
  font-weight: 600;
  font-family: monospace;
`;
