import React, { useContext, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "../../Helpers/Axios";
import styled from "styled-components";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { BadgeStyle, badgeData } from "./TaskBlog";
import parse from "html-react-parser";
import { Group, Text, Radio, Stack, TextInput, Button } from "@mantine/core";
import CustomFileInput from "../../Components/StudyAbroad/Apply/CustomFileInput";
import RichTextEditor from "@mantine/rte";
import LottieLoading from "../../Components/Loaders/LottieLoading";
import { convertToBase64, formatDate2 } from "../../Helpers";
import { AppContext } from "../../Helpers/Context";
import { useMediaQuery } from "@mantine/hooks";
import { showNotification } from '@mantine/notifications'; // Add this import at the top

const SteamTask = ({ type }) => {
  const { id, unitId, subUnitId } = useParams();
  const tUnit = type === "steam" ? "week" : "section";
  const tSubUnit = type === "steam" ? "day" : "lesson";
  const [answers, setAnswers] = useState({});
  const [files, setFiles] = useState({});
  const { t } = useTranslation();
  const { user } = useContext(AppContext);
  const queryClient = useQueryClient();
  const xs = useMediaQuery("(max-width: 768px)");
  const isLastSubUnit = localStorage.getItem(`isLast${tSubUnit}`);
  const unitNumber = localStorage.getItem(`${tUnit}Number`);

  const navigate = useNavigate();

  const { data: subUnitContent, isLoading } = useQuery({
    queryKey: ["subUnitContent", id, unitId, subUnitId],
    queryFn: async () => {
      const response = await axios.get(
        `/${type === "steam" ? "steam/steam" : "bootcamp"}/${id}/${tUnit}/${unitId}/${
          tSubUnit === "day" ? "day" : "lessons"
        }/${subUnitId}/`
      );
      return response.data.data;
    },
  });

  const { data: solvedTask, isLoading: solvedLoading } = useQuery({
    queryKey: ["solvedTask", subUnitContent?.tasks[0].id, user?.id],
    queryFn: async () => {
      const response = await axios.get(
        `/${type === "steam" ? "steam/steam" : "bootcamp"}/task/${
          subUnitContent?.tasks[0].id
        }/user/${user?.id}/`
      );
      return response.data;
    },
    enabled: !!user?.id && !!subUnitContent?.tasks[0].id,
  });

  const { data: subUnits,  } = useQuery({
    queryKey: ["subUnits", unitId],
    queryFn: async () => {
      const response = await axios.get(
        `${
          type === "steam" ? "steam/steam" : "bootcamp"
        }/${id}/${tUnit}/${unitId}/${tSubUnit}s`
      );
      return response.data.data;
    },
    enabled: !!unitId && !!id,
  });
  const nextSubUnit = subUnits?.find(subUnit => subUnit.id > subUnitId)



  const mutation = useMutation({
    mutationFn: (data) => {
      return axios.post(
        `/${type === "steam" ? "steam/steam" : "bootcamp"}/task/${
          subUnitContent?.tasks[0].id
        }/solve`,
        data
      );
    },
    onSuccess: () => {
      // Handle success (e.g., show a success message)
      queryClient.invalidateQueries([
        "solvedTask",
        subUnitContent?.tasks[0].id,
        user?.id,
      ]);
      queryClient.invalidateQueries(["subUnits", unitId]);
      showNotification({
        title: t('success'),
        message: t('task_submitted_successfully'),
        color: 'green',
        autoClose: 5000,
      });
      

      if (isLastSubUnit === "true") {
        navigate(
          `/${type}/${id}/${tUnit}-pathway?${tUnit}-${unitNumber}-Completed=true`
        );
      } else if (nextSubUnit) {
        navigate(`/${type}/${id}/${tUnit}-pathway/${unitId}/${tSubUnit}/${nextSubUnit?.id}`)
      }  else {
        navigate(
          `/${type}/${id}/${tUnit}-pathway?${tUnit}-${unitNumber}-Completed=true`
        );
      }
    },
    onError: (error) => {
      // Handle error (e.g., show an error message)
    },
  });

  const { data: reviewData } = useQuery({
    queryKey: ["reviewData", subUnitContent?.tasks[0]?.id, user?.id],
    queryFn: async () => {
      const response = await axios.get(
        `/${type === "steam" ? "steam/steam" : "bootcamp"}/task/${
          subUnitContent?.tasks[0].id
        }/user/${user?.id}/review`
      );
      return response.data;
    },
    enabled: !!user?.id && !!subUnitContent?.tasks[0]?.id,
  });

  const handleChange = (questionId, newValue) => {
    setAnswers((prev) => ({
      ...prev,
      [questionId]: { ...prev[questionId], answer_text: newValue },
    }));
  };

  const handleFileChange = (questionId, file) => {
    setFiles((prev) => ({
      ...prev,
      [questionId]: file,
    }));
  };

  const isQuiz = subUnitContent?.tasks[0].task_type === "Quiz" ? true : false;
  const questions = subUnitContent?.tasks[0]?.questions || [];

  const handleRadioChange = (questionId, answerText) => {
    setAnswers((prev) => ({
      ...prev,
      [questionId]: answerText,
    }));
  };

  const processQuestionText = (htmlContent) => {
    // Create a temporary div to parse HTML content
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;

    // Replace all links with "Link" text and arrow, but keep them clickable
    const links = tempDiv.getElementsByTagName('a');
    Array.from(links).forEach(link => {
      const originalUrl = link.href;
      const span = document.createElement('a');
      span.href = originalUrl;
      span.target = "_blank"; // Opens in new tab
      span.rel = "noopener noreferrer"; // Security best practice
      span.innerHTML = `Yükləmə linki <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12l14 0"/><path d="M13 18l6 -6"/><path d="M13 6l6 6"/></svg>`;

      span.style.color = '#FE6027';
      span.style.cursor = 'pointer';
      span.style.textDecoration = 'none';
      link.parentNode.replaceChild(span, link);
    });

    return tempDiv.innerHTML;
  };

  const handleNextSubUnit = () => {
    if (nextSubUnit) {
      navigate(`/${type}/${id}/${tUnit}-pathway/${unitId}/${tSubUnit}/${nextSubUnit?.id}`)
    } else {
      navigate(`/${type}/${id}/${tUnit}-pathway`)
    }
  }

  if (isLoading || solvedLoading) return <LottieLoading />;

  const handleSubmit = async () => {
    const questions = subUnitContent?.tasks[0]?.questions || [];
    
    // Check if all questions are answered
    const hasUnansweredQuestions = questions.some(question => {
      if (isQuiz) {
        return !answers[question.id];
      } else {
        return !answers[question.id]?.answer_text;
      }
    });

    if (hasUnansweredQuestions) {
      showNotification({
        title: t('error'),
        message: t('Zəhmət olmasa bütün sualları cavablayın'),
        color: 'red',
        autoClose: 5000,
      });
      return;
    }

    const submissionData = {
      questions: await Promise.all(
        questions.map(async (question) => {
          // Prepare answer files as separate objects
          const answerFiles = files[question.id]
            ? await Promise.all(
                files[question.id].map(async (file) => ({
                  file: await convertToBase64(file),
                }))
              )
            : [];

          return {
            question_id: question.id,
            answer_files: answerFiles,
            answer_text: isQuiz
              ? answers[question.id] || ""
              : answers[question.id]?.answer_text || "",
          };
        })
      ),
    };

    mutation.mutate(submissionData);
  };

  const renderAnswers = () => {
    const answers = type === 'steam' ? solvedTask.student_answers : solvedTask.bootcamp_student_answers;

    if (!solvedTask || !answers) return null;
    return answers.map((answer, index) => (
      <div key={index} style={{ marginTop: "20px" }} className="blog-container">
        <div className="blog-content">
          <Text mb={20} color="#364152" size={18} weight={500}>
            <span>Question {index + 1}</span>{" "}
            {parse(processQuestionText(answer.task_question.question_text))}
          </Text>
          {solvedTask.is_quiz ? (
            <OptionWrapper>
              {answer.task_question.answers.map((option, optionIndex) => {
                const isSelected = option.answer_text === answer.answer_text;
                const isCorrect = option.answer_text === answer.correct_answer;
                let backgroundColor = "transparent";
                // if (isSelected) {
                //   backgroundColor = isCorrect ? "#e6ffe6" : "#ffe6e6";
                // } else if (isCorrect) {
                //   backgroundColor = "#e6ffe6";
                // }

                return (
                  <Stack key={optionIndex} mb={10} mt={10} spacing={1}>
                    <OptionItem style={{ backgroundColor }}>
                      <Radio
                        checked={isSelected}
                        readOnly
                        color={
                          isSelected && isCorrect
                            ? "green"
                            : isSelected && !isCorrect
                            ? "red"
                            : isCorrect && "green"
                        }
                      />
                      <TextInput
                        value={option.answer_text}
                        radius={10}
                        style={{
                          flexGrow: 1,
                          height: "50px",
                          background:
                            isSelected && isCorrect
                              ? "#e6ffe6"
                              : isSelected && !isCorrect
                              ? "#ffe6e6"
                              : isCorrect && "#e6ffe6",
                        }}
                        disabled
                        height={50}
                      />
                    </OptionItem>
                  </Stack>
                );
              })}
            </OptionWrapper>
          ) : (
            <>
              <Stack spacing={2}>
                <Text mb={10} size={14} weight={500} color="#121926">
                  File
                </Text>
                {answer.answer_files.length > 0 &&
                  answer.answer_files.map((file) => (
                    <CustomFileInput
                      key={index}
                      t={t}
                      type="download"
                      to={file.file_output}
                      disabled={true}
                    />
                  ))}
              </Stack>
              <Stack mt={20} spacing={2}>
                <Text mb={10} size={14} weight={500} color="#121926">
                  Answer
                </Text>
                <RichTextEditor
                  style={{
                    cursor: "not-allowed",
                    background: "#f5f5f5",
                    color: "#697586",
                    borderRadius: "10px",
                  }}
                  value={answer.answer_text}
                  readOnly
                />
              </Stack>
            </>
          )}
        </div>
      </div>
    ));
  };

  return (
    <TaskBlogStyle xs={xs}>
      <Helmet>
        <title>
          {t("taskool_task_title", {
            taskName: subUnitContent?.tasks[0].task_name,
          })}
        </title>
        <meta
          name="description"
          content={t("taskool_task_description", {
            taskName: subUnitContent?.tasks[0].task_name,
          })}
        />
      </Helmet>
      <div className="blog-container">
        <div className="blog-content">
          <img
            style={{
              width: "100%",
              height: xs ? "224px" : "441px",
              objectFit: "fill",
              borderRadius: "11px",
            }}
            src={subUnitContent && subUnitContent[`${tSubUnit}_photo_output`]}
            alt="Blog img"
          />
          <Text mt={4} mb={6} size={xs ? 13 : 14} weight={500} color="#6941C6">
            {formatDate2(
              subUnitContent?.tasks && subUnitContent?.tasks[0].created_at
            )}
          </Text>
          <Text mt={10} size={xs ? 18 : 24} weight={500} color="#1A1A1A">
            {subUnitContent?.tasks[0].task_name}
          </Text>
          <Group mt={15} mb={25}>
            {subUnitContent?.skills.map((data, index) => (
              <BadgeStyle
                key={index}
                color={badgeData[index].color}
                bg={badgeData[index].bgColor}
              >
                #{data.name}
              </BadgeStyle>
            ))}
          </Group>
          <Text
            style={{ lineHeight: "24px" }}
            size={xs ? 14 : 16}
            weight={400}
            color="#667085"
          >
            {parse(processQuestionText(subUnitContent?.tasks[0].task_description))}
          </Text>
        </div>
      </div>

      {isQuiz &&
        !solvedTask &&
        questions.map((question, qIndex) => (
          <div
            key={qIndex}
            style={{ marginTop: "20px" }}
            className="blog-container"
          >
            <div className="blog-content">
              <Text
                style={{ display: "flex", alignItems: "center" }}
                mb={20}
                color="#364152"
                size={18}
                weight={500}
              >
                {qIndex + 1}. {parse(processQuestionText(question?.question_text))}
              </Text>
              <Label>Options</Label>
              <OptionWrapper>
                <Radio.Group
                  className="option-wrapper"
                  value={answers[question.id] || ""}
                  onChange={(value) => handleRadioChange(question.id, value)}
                >
                  {question.answers.map((answer, aIndex) => (
                    <OptionItem key={aIndex}>
                      <Radio value={answer.answer_text} label="" />
                      <TextInput
                        value={answer.answer_text}
                        radius={10}
                        style={{
                          width: "100%",
                          flexGrow: 1,
                          background: "#fff",
                        }}
                        styles={() => ({
                          input: {
                            backgroundColor: "#fff",
                            "&:disabled": {
                              backgroundColor: "#fff",
                              color: "#202939",
                              cursor: "not-allowed",
                              opacity: 1,
                            },
                          },
                        })}
                        className="text-input"
                        disabled
                      />
                    </OptionItem>
                  ))}
                </Radio.Group>
              </OptionWrapper>
            </div>
          </div>
        ))}

      {!isQuiz &&
        !solvedTask &&
        questions.map((question, qIndex) => (
          <div
            key={qIndex}
            style={{ marginTop: "20px" }}
            className="blog-container"
          >
            <div className="blog-content">
              <Text mb={20} color="#364152" size={18} weight={500}>
                <span>{t("task")} {qIndex + 1}</span>{" "}
                {parse(processQuestionText(question?.question_text))}
              </Text>
              <Stack spacing={2}>
                <Text mb={10} size={14} weight={500} color="#121926">
                  {t("file")}
                </Text>
                <CustomFileInput
                  addFileToState={(file) => handleFileChange(question.id, file)}
                  t={t}
                  type="upload"
                  name={`steam-file-${question.id}`}
                />
              </Stack>

              <Stack mt={20} spacing={2}>
                <Text mb={10} size={14} weight={500} color="#121926">
                  {t("answer")}
                </Text>
                <RichTextEditor
                  value={answers[question.id]?.answer_text || ""}
                  onChange={(newValue) => handleChange(question.id, newValue)}
                />
              </Stack>
            </div>
          </div>
        ))}
      {solvedTask ? (
        <>
          {renderAnswers()}
          <div style={{ width: "96%", margin: "20px auto", textAlign: "center" }}>
        <Button
          fullWidth
          mt={30}
          radius={10}
          onClick={handleNextSubUnit}
          styles={() => ({
            root: {
              backgroundColor: "#FE6027",
              color: "#fff",
              width: "100%",
              height: "56px",
              fontSize: "18px",
              fontWeight: "500",
              "&:hover": {
                backgroundColor: "#FE6027",
              },
            },
          })}
        >
          {t(`next_${tSubUnit}`)}
        </Button>
      </div> 
          
        </>
      ) : (
        <div style={{ width: "96%", margin: "20px auto", textAlign: "center" }}>
          <Button
            styles={() => ({
              root: {
                background: "#FE6027",
                color: "white",
                fontWeight: 500,
                height: "56px",
                width: "100%",
                borderRadius: "10px",
                fontSize: "16px",
                "&:hover": {
                  background: "#FE6027",
                },
              },
            })}
            onClick={handleSubmit}
            disabled={mutation.isLoading}
          >
            {mutation.isLoading ? t("submitting") : t("submit_task")}
          </Button>
        </div>
      )}
      {reviewData && (
        <>
          <div style={{ marginTop: "20px" }} className="blog-container">
            <div className="blog-content">
              <Text
                style={{ display: "flex", alignItems: "center" }}
                mb={20}
                color="#364152"
                size={18}
                weight={500}
              >
                {type === "steam" ? "STEAM" : "Bootcamp"} {t("creator_comment")}
              </Text>
              <RichTextEditor
                style={{
                  cursor: "not-allowed",
                  background: "#f5f5f5",
                  color: "#697586",
                  borderRadius: "10px",
                }}
                value={reviewData?.data?.comment}
                readOnly
              />
            </div>
          </div>
          <div style={{ marginTop: "20px" }} className="blog-container">
            <div className="blog-content">
              <Text
                style={{ display: "flex", alignItems: "center" }}
                mb={20}
                color="#364152"
                size={18}
                weight={500}
              >
                Score
              </Text>
              <TextInput
                value={reviewData?.data?.score}
                disabled={!!reviewData?.data}
                styles={(theme) => ({
                  input: {
                    height: "50px",
                    borderRadius: "10px",
                    fontSize: "16px",
                    backgroundColor: reviewData?.data ? "#f5f5f5" : "white",
                  },
                })}
              />
            </div>
          </div>
        </>
      )}
    </TaskBlogStyle>
  );
};

export default SteamTask;

const TaskBlogStyle = styled.div`
  background-color: #eff3f4;
  padding: 25px 0px;

  .blog-container {
    max-width: 96%;
    margin: 0px auto;
    background-color: white;

    border-radius: 13px;
    .blog-content {
      padding: 30px;
    }
  }
  .mantine-FileInput-placeholder.mantine-167ugbe {
    color: #004;
  }
  .mantine-FileInput-input.mantine-Input-input.mantine-FileInput-input.mantine-Input-disabled.mantine-FileInput-disabled.mantine-unuyvl {
    background-color: #f5f5f5;
  }
  .mantine-InputWrapper-root.mantine-TextInput-root.mantine-1q12vd2 {
    .mantine-Input-wrapper.mantine-TextInput-wrapper.mantine-12sbrde {
      border-radius: 10px;
      height: 50px;
      input {
        border-radius: 10px;
        height: 50px;
      }
    }
    border-radius: 10px;
    height: 50px;
  }
  .option-wrapper {
    .mantine-Group-root.mantine-jl3r5k[role="radiogroup"] {
      display: flex;
      flex-direction: column;
      align-items: normal;
      gap: 10px;
    }
  }
  @media (max-width: 768px) {
    max-width: 100%;
    .blog-content {
      padding: 15px 10px !important;
    }
  }
`;

const Label = styled.label`
  color: black;
  font-size: 16px;
  font-weight: 500;
  margin-left: 5px;
  color: #121926;
`;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const OptionItem = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    .text-input{
    input {
    height: 50px;
  `;
