import React, { useState } from "react";
import { Button, Group, Stack, Text } from "@mantine/core";
import styled from "styled-components";
import { DeviceFloppy } from "tabler-icons-react";
import fb from "../../../Assets/SVGs/profile/fb.svg";
import x from "../../../Assets/SVGs/profile/x.svg";
import linkedIn from "../../../Assets/SVGs/profile/in.svg";
import wp from "../../../Assets/SVGs/profile/wp.svg";
import clip from "../../../Assets/SVGs/profile/clip.svg";
import QRCode from "qrcode.react";
import { useMediaQuery } from "@mantine/hooks";


const ShareProfile = ({t, url }) => {
  const [copied, setCopied] = useState(false);

  const handleSaveImage = () => {
    const canvas = document.querySelector("canvas");
    const url = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.href = url;
    link.download = "qrcode.png";
    link.click();
  };

  const handleShareFacebook = () => {
    const text = `${t("here-my-taskilled-account")}`;
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      url
    )}&quote=${encodeURIComponent(text)}`;
    window.open(facebookShareUrl, "_blank");
  };

  const handleShareTwitter = () => {
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      `${t("here-my-taskilled-account")}`
    )} ${encodeURIComponent(url)}`;
    window.open(twitterShareUrl, "_blank");
  };

  const handleShareLinkedIn = () => {
    const text = `${t("here-my-taskilled-account")}`;
    const linkedinShareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
      url
    )}&title=${encodeURIComponent(text)}`;
    window.open(linkedinShareUrl, "_blank");
  };

  const handleShareWhatsApp = () => {
    const text = `${t("here-my-taskilled-account")} ${url}`;
    const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      text
    )}`;
    window.open(whatsappShareUrl, "_blank");
  };

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(url).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  const sm = useMediaQuery("(max-width: 768px)");

  return (
    <Container>
      <Wrapper sm={sm}>
        <Text style={{textAlign: sm ? "center" : "left"}} size={sm ? 15 : 17} weight={500} color="#364152">
          {t('open-qr-code')}
        </Text>
        <Stack justify="center" align="center" spacing={sm ? 3 : 5}>

          <QRCode
            style={sm 
              ? { width: "120px", height: "120px", margin: "15px 0" }
              : { width: "130px", height: "130px", margin: "20px 0" }
            }
            value={url}


          />
          <Button
            onClick={() => handleSaveImage()}
            styles={() => ({
              root: {
                border: "none",
                backgroundColor: "#343A40",
                color: "#ffffff",
                fontWeight: 500,
                fontSize: sm ? 13 : 14,
                padding: sm ? "4px 20px" : "6px 26px",
                borderRadius: 9,
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#343a40",
                },
              },
            })}
            leftIcon={<DeviceFloppy size={sm ? 15 : 17} />}
          >
            {t('save-qr-image')}
          </Button>
          <Line />
          <Text size={17} weight={500} color="#364152">
            {t('share')}
          </Text>
          <Group mt={8} noWrap spacing={8}>
            <IconContainer onClick={handleShareFacebook}>
              <img width={9} height={22} src={fb} alt="fb" />
            </IconContainer>
            <IconContainer onClick={handleShareTwitter}>
              <img width={19} height={19} src={x} alt="x" />
            </IconContainer>
            <IconContainer onClick={handleShareLinkedIn}>
              <img width={18} height={17} src={linkedIn} alt="linkedin" />
            </IconContainer>
            <IconContainer onClick={handleShareWhatsApp}>
              <img width={18} height={18} src={wp} alt="wp" />
            </IconContainer>
            <IconContainer onClick={handleCopyUrl}>
              <img width={21} height={21} src={clip} alt="clip" />
              {copied && <CopiedText>{t('copied')}</CopiedText>}
            </IconContainer>
          </Group>
        </Stack>
      </Wrapper>
    </Container>
  );
};

export default ShareProfile;

const Container = styled.div`
  box-shadow: 0px 1px 10px 0px #0000001a;
  background: #fff;
  border-radius: 11px;
`;

const Wrapper = styled.div`
  padding: ${props => props.sm ? "20px 25px" : "25px 35px"};
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #bcbcbc;
  margin: 10px 0;
`;

const IconContainer = styled.div`
  width: ${props => props.sm ? "35px" : "42px"};
  height: ${props => props.sm ? "30px" : "35px"};
  border-radius: 7px;
  border: 1px solid #a1a6a4;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
`;

const CopiedText = styled.div`
  position: absolute;
  top: -25px;
  background: #000;
  color: #fff;
  padding: 2px 5px;
  border-radius: 4px;
  font-size: 12px;
`;
